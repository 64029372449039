import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Employee, Team} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";
import {ValidationError} from "../api/api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {dateToString} from "../planning/util/datetime";


export const CancelPlannedChangeModal: FC<{team: Team, employee: Employee, date: Date, membershipId: string}> = (props) => {
  const {cancelPlannedChange} = useApi()
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    await cancelPlannedChange(props.team.id, props.membershipId)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <div className={"space-y-2"}>
    <p>
      Let op, je staat op het punt de volgende wijziging te annuleren:
    </p>
    <div className={`border border-slate-200 rounded px-3 text-sm text-slate-700 py-2`}>
      <div className={'flex items-center'}>
        <FontAwesomeIcon icon={faClock} className={"w-4 mr-2"} />
        <div>
          <h3 className={"font-medium"}>{dateToString(props.date)}</h3>
        </div>
      </div>
      <div className={'flex items-center'}>
        <FontAwesomeIcon icon={fa.faShuffle} className={"w-4 mr-2"} />
        <div>
          <h3><strong>{props.employee.firstName} {props.employee.lastName}</strong> naar <strong>{props.team.name}</strong></h3>
        </div>
      </div>
    </div>
    <p>Weet je het zeker?</p>
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter icon={fa.faCancel} text={"Ja, annuleer wijziging"} onSubmit={confirm} />
  </div>
}
