import React, {FC, useEffect, useMemo} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Employee, Order, Workorder, WorkorderFeedback} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter, useModalControls} from "../components/layout/ModalProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPerson, faUser} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../components/form/Button";


export const WorkorderDetailsModal: FC<{order: Workorder}> = (props) => {
  const {close} = useModalControls()
  const {employees} = useApi()
  const employeesMap = useMemo(() => {
    return employees.reduce((acc, e) => {
      acc[e.id] = e
      return acc
    }, {} as {[key: string]: Employee})
  }, [employees])
  // Answers
  const questionAnswers = useMemo(() => props.order.feedback?.filter(f => f.answerType === 'questions'), [props.order])
  const [answerPage, setAnswerPage] = React.useState(0)
  useEffect(() => {
    if (questionAnswers && questionAnswers.length < (answerPage + 1)) {
      setAnswerPage(questionAnswers.length - 1)
    }
  }, [answerPage, questionAnswers]);
  // Fotos
  const questionImages = useMemo(() => props.order.feedback?.filter(f => f.answerType === 'image'), [props.order])
  const [imagePage, setImagePage] = React.useState(0)
  useEffect(() => {
    if (questionImages && questionImages.length < (imagePage + 1)) {
      setImagePage(questionImages.length - 1)
    }
  }, [imagePage, questionImages]);
  return <>
    {/* Werkbon */}
    <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
      <div className={"font-medium text-sm"}>Project #{props.order.projectNumber}</div>
      <div className={"text-sm"}>{props.order.projectDescription ?? "Overig project"}</div>
      <div className={"text-sm"}>{props.order.description}</div>
    </div>
    {questionAnswers?.[answerPage] && <>
      <h2 className={"font-medium"}>Vragen:</h2>
      <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
        <div className={"py-2"}>
          <WorkorderDetailsQuestionFeedback feedback={questionAnswers?.[answerPage]} employeesMap={employeesMap}/>
        </div>
        <div className={"-mx-3 px-3 mt-2 border-t border-slate-200"}>
          {questionAnswers?.map((_, k) => {
            return <button key={k}
                           className={`h-6 w-6 mt-1 mr-1 rounded-full text-sm font-medium border ${k === answerPage ? 'border-blue-900 bg-blue-900 text-white' : 'border-slate-200 text-blue-600'}`}
                           onClick={() => setAnswerPage(k)}>{k + 1}</button>
          })}
        </div>
      </div>
    </>}
    {questionImages?.[imagePage] && <>
      <h2 className={"font-medium"}>Fotos:</h2>
      <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
        <div className={"py-2"}>
          <WorkorderDetailsImageFeedback feedback={questionImages?.[imagePage]} employeesMap={employeesMap}/>
        </div>
        <div className={"-mx-3 px-3 mt-2 border-t border-slate-200"}>
          {questionImages?.map((_, k) => {
            return <button key={k}
                           className={`h-6 w-6 mt-1 mr-1 rounded-full text-sm font-medium border ${k === imagePage ? 'border-blue-900 bg-blue-900 text-white' : 'border-slate-200 text-blue-600'}`}
                           onClick={() => setImagePage(k)}>{k + 1}</button>
          })}
        </div>
      </div>
    </>}
    <footer className={"-mx-4 px-4 mt-3 py-3 border-t border-slate-100 flex items-center space-x-4"}>
      <Button type={'secondary'} size={'md'} text={'Terug'} onClick={() => close()}/>
    </footer>
  </>
}

const WorkorderDetailsQuestionFeedback: FC<{
  feedback: WorkorderFeedback,
  employeesMap: { [id: string]: Employee }
}> = props => {
  const employee = props.employeesMap[props.feedback.employeeId]
  const answers = ('questions' in props.feedback.answer ? props.feedback.answer['questions'] : []) as {
    answer: string,
    question: string
  }[]
  return <div>
    <div className={"text-blue-900 ml-2"}>
      <FontAwesomeIcon icon={faUser} className={"mr-2"}/>
      {employee?.firstName} {employee?.lastName}
    </div>
    <dl>
      {answers.map((a, k) => {
        return <React.Fragment key={k}>
          <dt className={"font-medium mt-2"}>{a.question}</dt>
          <dd className={"ml-2 text-sm text-slate-700"}>{a.answer}</dd>
        </React.Fragment>
      })}
    </dl>
  </div>
}

const WorkorderDetailsImageFeedback: FC<{
  feedback: WorkorderFeedback,
  employeesMap: { [id: string]: Employee }
}> = props => {
  const employee = props.employeesMap[props.feedback.employeeId]
  const imageUrl = ('url' in props.feedback.answer ? props.feedback.answer['url'] : '') as string
  const [zoomed, setZoomed] = React.useState(false)
  return <div>
    {zoomed && <div className={"fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center"} onClick={() => setZoomed(false)}>
      <img src={imageUrl} alt={employee?.firstName} className={"rounded-lg max-h-full max-w-full cursor-zoom-out"}
           onClick={() => setZoomed(false)}/>
    </div>}
    <div className={"text-blue-900 ml-2"}>
      <FontAwesomeIcon icon={faUser} className={"mr-2"}/>
      {employee?.firstName} {employee?.lastName}
    </div>
    <div className={'h-[400px] flex items-center justify-center'}>
      <img src={imageUrl} alt={employee?.firstName} className={"rounded-lg max-h-full max-w-full cursor-zoom-in"} onClick={() => setZoomed(true)} />
    </div>
  </div>
}
