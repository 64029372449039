import React, {FC, useState} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface ButtonProps {
  type: "primary" | "secondary" | "danger",
  size: "xs" | "sm" | "md" | "lg",
  text: string,
  icon?: fa.IconDefinition,
  submit?: true,
  disabled?: boolean,
  onClick?: () => void | Promise<void>,
}

export const Button: FC<ButtonProps> = props => {
  const height: string = {
    "xs": "h-6",
    "sm": "h-8",
    "md": "h-10",
    "lg": "h-16"
  }[props.size]
  const textStyle: string = {
    "xs": "px-2 text-xs font-medium",
    "sm": "px-2 text-sm font-medium",
    "md": "px-3 text-base font-medium",
    "lg": "px-3 text-lg",
  }[props.size]
  const iconStyle: string = {
    "xs": "mr-2",
    "sm": "mr-1 w-4",
    "md": "mr-3 w-4",
    "lg": "mr-3 w-4",
  }[props.size]
  const type: string = {
    "primary": "bg-blue-900 hover:bg-blue-800 text-white",
    "secondary": "bg-transparent hover:bg-blue-100 text-blue-600 hover:text-blue-700",
    "danger": "bg-transparent hover:bg-red-100 text-red-600 hover:text-red-700",
  }[props.type]
  const disabledStyle: string = type === 'primary' ? "bg-slate-500 text-white cursor-not-allowed" : 'bg-transparent text-gray-400 cursor-not-allowed'
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onClick = async () => {
    if (props.disabled || isSubmitting || !props.onClick) return
    const result = props.onClick()
    if (result instanceof Promise) {
      setIsSubmitting(true)
      await result
      setIsSubmitting(false)
    }
  }
  return <button type={props.submit ? "submit" : "button"}
                 onClick={onClick}
                 disabled={props.disabled || isSubmitting}
                 className={`${height} ${(props.disabled || isSubmitting) ? disabledStyle : type} ${textStyle} rounded flex items-center justify-center`}
  >
    {(props.icon || isSubmitting) &&
      <FontAwesomeIcon icon={isSubmitting ? fa.faCircleNotch : props.icon!} spin={isSubmitting}
                       className={iconStyle}/>}{props.text}
  </button>
}
export const IconButton: FC<Pick<ButtonProps, 'type' | 'disabled' | 'size' | 'icon' | 'onClick'> & {
  icon: fa.IconDefinition
}> = props => {
  const height: string = {
    "xs": "h-8 w-8",
    "sm": "h-8 w-8",
    "md": "h-10 w-10",
    "lg": "h-16 w-16"
  }[props.size]
  const type: string = {
    "primary": "bg-blue-900 hover:bg-blue-800 text-white",
    "secondary": "bg-transparent hover:bg-blue-100 text-blue-600 hover:text-blue-700",
    "danger": "bg-transparent hover:bg-red-100 text-red-600 hover:text-red-700",
  }[props.type]
  const disabledStyle: string = type === 'primary' ? "bg-slate-500 text-white cursor-not-allowed" : 'bg-transparent text-gray-400 cursor-not-allowed'
  const [isSubmitting, setIsSubmitting] = useState(false)
  const onClick = async () => {
    if (props.disabled || isSubmitting || !props.onClick) return
    const result = props.onClick()
    if (result instanceof Promise) {
      setIsSubmitting(true)
      await result
      setIsSubmitting(false)
    }
  }
  return <button onClick={onClick} type={'button'}
                 className={`${height} ${(props.disabled || isSubmitting) ? disabledStyle : type} font-medium rounded flex justify-center items-center`}>
    <FontAwesomeIcon icon={isSubmitting ? fa.faCircleNotch : props.icon} spin={isSubmitting}/>
  </button>
}