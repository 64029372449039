import {FC} from "react";

export const Card: FC<{children: React.ReactNode, title: string}> = ({children, title}) => {
  return <div className={"bg-white text-black rounded my-8"}>
    <header className={"py-4 px-6 border-b border-gray-200 font-medium"}>{title}</header>
    <div className={"py-4 px-6"}>
      {children}
    </div>
  </div>
}
