import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";
import { Input } from "../components/form/Input";
import {ValidationError} from "../api/api";
import {Color} from "../api/dto";


export const EditColorNameModal: FC<{color: Color}> = ({color}) => {
  const {updateColor} = useApi()
  const [name, setName] = React.useState(color.name)
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    await updateColor(color.id, name, color.enabled, color.color)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <div className={"space-y-2"}>
    <Input label={'Naam'} value={name} focus={true} onChange={setName} type={'text'} />
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter icon={fa.faSave} text={"Opslaan"} onSubmit={confirm} />
  </div>
}
