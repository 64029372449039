import React, {FC} from "react";
import {usePlanner} from "../PlannerContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {useThemes} from "../util/theming";
import {useTaskTypes} from "../hooks/useTaskTypes";

export const FloatingCard: FC = () => {
    const {getTheme} = useThemes()
    const {floatingTask, mousePos} = usePlanner()
    const {getTaskColor, getTaskName} = useTaskTypes()
    if (floatingTask === null || mousePos === null) {
        return <></>
    }
    const theme = getTheme(getTaskColor(floatingTask) ?? undefined, floatingTask.isInvoiced)
    return <div className={`${theme.bg} z-10 shadow-lg rounded text-white text-sm flex items-stretch fixed h-10 w-[200px]`} style={{top: `calc(${mousePos.y}px - 1.25rem)`, left: `calc(${mousePos.x}px - 0.75rem)`}}>
        <button className={`${theme.lightText} ${theme.bgHover} bg-[#0003] text-base flex items-center justify-center w-6 cursor-move`}><FontAwesomeIcon icon={fa.faGrip} rotation={90} /></button>
        <div className={'flex-1 py-2 px-3'}>
          {getTaskName(floatingTask)}
        </div>
    </div>
}