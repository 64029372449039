import React, {FC, useContext, useEffect, useMemo} from "react";
import {Drawer} from "../planning/Drawer";
import {RealtimeContext} from "../realtime/RealtimeContext";
import {WeekPicker} from "../planning/WeekPicker";
import {WeekOverview} from "../planning/WeekOverview";
import {EmployeeAvatar} from "../personel/components/EmployeeAvatar";
import {useApi} from "../api/APIContext";
import {Employee} from "../api/dto";
import moment from "moment";
import {ALL_TEAMS_CATEGORY, usePlanner} from "../planning/PlannerContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faInfo, faMinus, faPlus, faTimes, faWarning} from "@fortawesome/free-solid-svg-icons";
import {Select} from "../components/form/Select";
import {usePersistentState} from "../util/usePersistentState";

export const Planning: FC = () => {

  return <main className={"select-none flex-1 flex items-stretch"}>
    <div className={"flex-1"}>
      <PlanningNav />
      <div className={"relative h-[calc(100%-5rem)]"}> {/* 5rem compensates the h-20 on the PlanningNav */}
        <div className={'overflow-x-scroll overflow-y-scroll absolute inset-0'}>
          <div className={"px-6 py-8"}>
            <WeekOverview/>
          </div>
        </div>
      </div>
    </div>
    <Drawer/>
  </main>
}

const PlanningNav: FC = () => {
  const {onlineUsers} = useContext(RealtimeContext)
  const {setDay, notifications, dismissNotification} = usePlanner()
  const {employees} = useApi()
  const onlineEmployees = useMemo(() => {
    return onlineUsers?.map(u => {
      const e = employees?.find(e => e.id === u.sub)
      if (!e) return null
      return {
        ...e,
        selectedDate: u.selectedDate,
      }
    }).filter(u => !! u) ?? []
  }, [onlineUsers, employees]) as (Employee&{selectedDate?: Date})[]
  return <nav
    className={"flex relative justify-between items-center h-20 shadow-sm px-6 bg-white border-b border-slate-100"}>
    <h1 className={"h-12 hidden xl:flex items-center text-2xl font-bold mr-4"}>Planning</h1>
    <WeekPicker/>
    <div className={"flex-1 hidden lg:flex items-center -space-x-4 mx-2 -my-2"}>
      {onlineEmployees.map((u, k) => {
        return <div className={"relative"} key={k}>
          <EmployeeAvatar employee={u}/>
          {u.selectedDate && <button
            className={"absolute rounded-full bg-blue-800 hover:bg-blue-900 text-xs font-medium text-white top-1 right-1 flex items-center justify-center h-5 w-6 z-10"}
            onClick={() => setDay(u.selectedDate!)}>
            <span>{moment(u.selectedDate).week()}</span>
          </button>}
        </div>
      })}
    </div>
    <div className={"flex items-center w-48 mr-4"}>
      <FontAwesomeIcon icon={faFilter} className={"mr-4 text-slate-600"}/>
      <TeamCategoryInput/>
    </div>
    <div className={"hidden 2xl:block"}>
      <GuiScaleInput />
    </div>
    <div className={"absolute top-full right-4 z-50 w-72 flex flex-col items-stretch"}>
      {notifications.map((n, i) => {
        return <div key={i}
                    className={"border border-slate-200 bg-white rounded-lg shadow p-2 flex items-stretch m-2 mb-0"}>
          <div className={"flex items-center"}>
            {n.level === 'info' &&
              <div className={"h-9 w-9 mr-3 rounded-full bg-blue-200 text-blue-800 flex items-center justify-center"}>
                <FontAwesomeIcon icon={faInfo}/>
              </div>}
            {n.level === 'warning' &&
              <div className={"h-9 w-9 mr-3 rounded-full bg-amber-200 text-amber-800 flex items-center justify-center"}>
                <FontAwesomeIcon icon={faWarning}/>
              </div>}
            {n.level === 'error' &&
              <div className={"h-9 w-9 mr-3 rounded-full bg-red-200 text-red-800 flex items-center justify-center"}>
                <FontAwesomeIcon icon={faWarning}/>
              </div>}
          </div>

          <div className={"flex-1"}>
            <h2 className={"text-slate-900 font-medium text-base"}>{n.title}</h2>
            <p className={"text-slate-600 text-sm overflow-ellipsis"}>{n.description}</p>
          </div>
          <div className={""}>
            <button
              className={"h-5 w-5 rounded hover:bg-slate-200 text-slate-600 hover:text-red-800 flex items-center justify-center"}
              onClick={() => dismissNotification(n)}>
              <FontAwesomeIcon icon={faTimes}/>
            </button>
          </div>
        </div>
      })}
    </div>
  </nav>
}

const TeamCategoryInput = () => {
  const {teams} = useApi()
  const {category, setCategory} = usePlanner()
  const categories = useMemo(() => {
    return Array.from(new Set(teams?.map(t => t.category) ?? []))
  }, [teams])

  useEffect(() => {
    if (categories.length > 0 && category !== ALL_TEAMS_CATEGORY && !categories.includes(category)) {
      setCategory(ALL_TEAMS_CATEGORY)
    }
  }, [categories, category])
  return <Select label={''}
                 options={Object.fromEntries([[ALL_TEAMS_CATEGORY, ALL_TEAMS_CATEGORY], ...categories.filter(c => !!c).map(c => [c, c])])}
                 value={category} onChange={setCategory}/>
}

const GuiScaleInput = () => {
  const {scaleConfig, setScale} = usePlanner()
  const GUI_SCALE_LOWER_LIMIT = 50
  const GUI_SCALE_UPPER_LIMIT = 200
  const GUI_SCALE_STEP = 10
  const GUI_SCALE_DEFAULT = 100
  const guiScale = scaleConfig.scale * 100
  const setGuiScale = (scale: number) => {
    if (scale < GUI_SCALE_LOWER_LIMIT || scale > GUI_SCALE_UPPER_LIMIT) return
    setScale(scale / 100)
  }


  const currentScale = Math.floor(guiScale ?? GUI_SCALE_DEFAULT)
  return <div>
    <button className={"h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setGuiScale(currentScale - GUI_SCALE_STEP)}><FontAwesomeIcon icon={faMinus} /></button>
    <span className={"text-sm font-medium mx-2"}>{currentScale}%</span>
    <button className={"h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setGuiScale(currentScale + GUI_SCALE_STEP)}><FontAwesomeIcon icon={faPlus} /></button>
  </div>
}