import {useContext, useEffect, useState} from "react";
import {useApiCall} from "../../api/api";
import {TeamAvailability} from "../../api/dto";
import {usePlanner} from "../PlannerContext";
import moment from "moment";
import {RealtimeContext} from "../../realtime/RealtimeContext";

export const useTeamAvailability = (): TeamAvailability|null => {
  const {day} = usePlanner()
  const {getTeamAvailability} = useApiCall()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [teamAvailability, setTeamAvailability] = useState<TeamAvailability|null>(null)
  const {teamAvailabilityUpdatedAt} = useContext(RealtimeContext)
  const [lastLoadedAt, setLastLoadedAt] = useState<number>(new Date().getTime())

  const load = async (date: Date): Promise<void> => {
    setIsLoading(true)
    await getTeamAvailability(date).then(setTeamAvailability)
    setIsLoading(false)
    setLastLoadedAt(new Date().getTime())
  }

  useEffect(() => {
    if (isLoading) {
      return
    }
    if (teamAvailability === null) {
      load(day)
      return
    }
    if (moment(day).week() !== teamAvailability.weekNumber) {
      // console.log(moment(day).week(), teamAvailability.weekNumber)
      load(day)
      return
    }
    if (teamAvailabilityUpdatedAt > lastLoadedAt) {
      load(day)
      return
    }

  }, [day, teamAvailability, isLoading, lastLoadedAt, teamAvailabilityUpdatedAt]);

  return isLoading ? null : teamAvailability
}