import React, {FC, useContext, useMemo} from "react";
import {Drawer} from "../planning/Drawer";
import {RealtimeContext} from "../realtime/RealtimeContext";
import {WeekPicker} from "../planning/WeekPicker";
import {EmployeeAvatar} from "../personel/components/EmployeeAvatar";
import {useApi} from "../api/APIContext";
import {Employee} from "../api/dto";
import moment from "moment";
import {usePlanner} from "../planning/PlannerContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo, faTimes, faWarning} from "@fortawesome/free-solid-svg-icons";
import {OrderOverview} from "../planning/OrderOverview";

export const Orders: FC = () => {

  return <main className={"select-none flex-1 flex items-stretch"}>
    <div className={"flex-1"}>
      <PlanningNav />
      <div className={"relative h-[calc(100%-5rem)]"}> {/* 5rem compensates the h-20 on the PlanningNav */}
        <div className={'overflow-x-scroll overflow-y-scroll absolute inset-0'}>
          <div className={"px-6 py-8"}>
            <OrderOverview />
          </div>
        </div>
      </div>
    </div>
    <Drawer/>
  </main>
}

const PlanningNav: FC = () => {
  const {onlineUsers} = useContext(RealtimeContext)
  const {setDay, notifications, dismissNotification} = usePlanner()
  const {employees} = useApi()
  const onlineEmployees = useMemo(() => {
    return onlineUsers?.map(u => {
      const e = employees?.find(e => e.id === u.sub)
      if (!e) return null
      return {
        ...e,
        selectedDate: u.selectedDate,
      }
    }).filter(u => !! u) ?? []
  }, [onlineUsers, employees]) as (Employee&{selectedDate?: Date})[]
  return <nav
    className={"flex relative justify-between items-center h-20 shadow-sm px-6 bg-white border-b border-slate-100"}>
    <h1 className={"h-12 hidden xl:flex items-center text-2xl font-bold mr-4"}>Bestellingen</h1>
    <WeekPicker/>
    <div className={"flex-1 hidden lg:flex items-center -space-x-4 mx-2 -my-2"}>
      {onlineEmployees.map((u, k) => {
        return <div className={"relative"} key={k}>
          <EmployeeAvatar employee={u}/>
          {u.selectedDate && <button
            className={"absolute rounded-full bg-blue-800 hover:bg-blue-900 text-xs font-medium text-white top-1 right-1 flex items-center justify-center h-5 w-6 z-10"}
            onClick={() => setDay(u.selectedDate!)}>
            <span>{moment(u.selectedDate).week()}</span>
          </button>}
        </div>
      })}
    </div>
    <div className={"absolute top-full right-4 z-50 w-72 flex flex-col items-stretch"}>
      {notifications.map((n, i) => {
        return <div key={i}
                    className={"border border-slate-200 bg-white rounded-lg shadow p-2 flex items-stretch m-2 mb-0"}>
          <div className={"flex items-center"}>
            {n.level === 'info' &&
              <div className={"h-9 w-9 mr-3 rounded-full bg-blue-200 text-blue-800 flex items-center justify-center"}>
                <FontAwesomeIcon icon={faInfo}/>
              </div>}
            {n.level === 'warning' &&
              <div className={"h-9 w-9 mr-3 rounded-full bg-amber-200 text-amber-800 flex items-center justify-center"}>
                <FontAwesomeIcon icon={faWarning}/>
              </div>}
            {n.level === 'error' &&
              <div className={"h-9 w-9 mr-3 rounded-full bg-red-200 text-red-800 flex items-center justify-center"}>
                <FontAwesomeIcon icon={faWarning}/>
              </div>}
          </div>

          <div className={"flex-1"}>
            <h2 className={"text-slate-900 font-medium text-base"}>{n.title}</h2>
            <p className={"text-slate-600 text-sm overflow-ellipsis"}>{n.description}</p>
          </div>
          <div className={""}>
            <button
              className={"h-5 w-5 rounded hover:bg-slate-200 text-slate-600 hover:text-red-800 flex items-center justify-center"}
              onClick={() => dismissNotification(n)}>
              <FontAwesomeIcon icon={faTimes}/>
            </button>
          </div>
        </div>
      })}
    </div>
  </nav>
}
