import React, {FC} from "react";
import {Card} from "../components/Card";
import {TeamManager} from "../team/components/TeamManager";

export const Teams: FC = () => {

  return (
    <main className={"flex-1 overflow-y-scroll max-h-[calc(100vh-4rem)]"}>
      <div className={"mx-6 my-8"}>
        <div className={"max-w-screen-2xl mx-auto"}>
          <Card title={'Teams'}>
            <div className={"flex justify-between items-center h-12 mb-4"}>
              <h1 className={"flex items-center text-2xl font-bold"}>Teams</h1>
            </div>
            <TeamManager />
          </Card>
        </div>
      </div>
    </main>
  );
}









