import {Button} from "../../components/form/Button";
import {faChevronDown, faChevronUp, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Toggle} from "../../components/form/Toggle";
import {Input} from "../../components/form/Input";
import React, {useMemo, useState} from "react";
import {EmployeeAvatar} from "./EmployeeAvatar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EmployeeDetails} from "./EmployeeDetails";
import {useApi} from "../../api/APIContext";
import {useModal} from "../../components/layout/ModalProvider";
import {AddEmployeeModal} from "../../modals/AddEmployeeModal";

export const EmployeeManager = () => {
    const {employees} = useApi()
    const [filter, setFilter] = useState('')
    const [onlyActive, setOnlyActive] = useState(true)
    const [expanded, setExpanded] = useState<string|null>(null)
    const filteredEmployees = useMemo(() => {
        return employees.filter(e => `${e.firstName},${e.lastName},${e.email??''}`.toLowerCase().includes(filter))
            .filter(e => onlyActive ? e.enabled : true)
    }, [employees, filter, onlyActive])
    const addEmployeeModal = useModal({title: 'Nieuw personeel', body: <AddEmployeeModal />})
    return <>
        <div className={"flex justify-between items-center h-12 mb-4"}>
            <h1 className={"flex items-center text-2xl font-bold"}>Personeel</h1>
            <Button icon={faPlus} onClick={() => addEmployeeModal.open()} text={'Nieuw personeel'} type={'primary'} size={'md'} />
        </div>

        <div className={"flex justify-between items-center mb-4"}>
            <label className={`h-10 px-4 flex cursor-pointer items-center space-x-3 ${onlyActive ? 'bg-blue-100' : ''} rounded-lg font-medium`}>
                <Toggle value={onlyActive} onChange={setOnlyActive} />
                <span>Alleen actief personeel</span>
            </label>
            <div className={"max-w-screen-sm"}>
                <Input value={filter} onChange={setFilter} type={'text'} placeholder={'Zoeken...'} label={''} />
            </div>
        </div>

        <div className={'bg-white border border-slate-100 rounded'}>
            {filteredEmployees.length === 0 && <div className={"text-center my-8 font-medium text-slate-600"}>Geen resultaten</div>}
            <table className={"w-full"}>
                <tbody>
                {filteredEmployees.map((e, i) => <React.Fragment key={i}>
                    <tr className={`text-sm ${i !== 0 ? 'border-t border-slate-100' : ''}`}>
                        <td><EmployeeAvatar employee={e} /></td>
                        <td className={"px-3"}>{e.firstName} {e.lastName}</td>
                        <td className={"px-3 text-center"}>{e.email ? <span className={"px-2 py-1 bg-blue-200 text-blue-600 rounded-full text-xs"}>Kan inloggen</span> : <span className={"px-2 py-1 text-slate-600 text-xs"}>Kan niet inloggen</span>}</td>
                        <td className={"px-3"}>
                            <span className={"text-xs mx-1 uppercase tracking-wide inline-block px-1 rounded border border-blue-300 text-blue-400"}>{e.role}</span>
                            <span className={`text-xs mx-1 uppercase tracking-wide inline-block px-1 rounded border ${e.enabled ? 'border-blue-300 text-blue-400' : 'border-red-300 text-red-400'}`}>{e.enabled ? "Actief" : "Non-actief"}</span>
                        </td>
                        <td className={"px-3 text-right"}>
                            <button className={'w-8 h-8 hover:bg-slate-100 rounded-full'} onClick={() => expanded === e.id ? setExpanded(null) : setExpanded(e.id)}>
                                <FontAwesomeIcon icon={expanded === e.id ? faChevronUp : faChevronDown} />
                            </button>
                        </td>
                    </tr>
                    {expanded === e.id && <tr className={""}>
                        <td colSpan={5} className={"p-3 pt-0"}>
                            <EmployeeDetails employee={e} />
                        </td>
                    </tr>}
                </React.Fragment>)}
                </tbody>
            </table>
        </div>

    </>
}