import {FC, ReactElement} from "react";
import {useApi} from "../../api/APIContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch} from "@fortawesome/free-solid-svg-icons";

export const GlobalLoadingScreen: FC<{children: ReactElement}> = ({children}) => {
  const {initialLoading} = useApi()
  if (initialLoading) {
    return <>
      <div className={"fixed z-50 inset-0 bg-[#0002] flex items-center justify-center"}>
        <div className={"max-w-xs bg-white p-8 text-center rounded-lg shadow flex flex-col items-center space-y-4"}>
          <h2 className={"text-lg font-medium text-slate-800"}>Projecten inladen...</h2>
          <p className={"text-sm text-slate-600"}>Alle projecten en taken worden opgehaald uit <span className={"font-medium"}>Proteus</span></p>
          <FontAwesomeIcon icon={faCircleNotch} spin={true} className={"text-blue-900 text-4xl"} />
        </div>
      </div>
      {children}
    </>
  }
  return children
}