import {Project, Task} from "../../api/dto";
import React, {FC, useState} from "react";
import {
  faCircleNotch,
  faCopy, faEuro,
  faInfoCircle,
  faPalette, faPencil, faRightLeft,
  faTimes,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useModal} from "../../components/layout/ModalProvider";
import {DeleteTaskModal} from "../../modals/DeleteTaskModal";
import {ChangeTaskColorModal} from "../../modals/ChangeTaskColorModal";
import {CopyTaskModal} from "../../modals/CopyTaskModal";
import {TaskDetailsModal} from "../../modals/TaskDetailsModal";
import {usePlanner} from "../PlannerContext";
import {EditTaskModal} from "../../modals/EditTaskModal";
import {MoveTaskModal} from "../../modals/MoveTaskModal";
import {useApi} from "../../api/APIContext";

interface TaskContextMenuProps {
  task: Task;
  project?: Project
  children: React.ReactNode
  onLeftMouseDown: () => void
  className: string
}
export const TaskContextMenu: FC<TaskContextMenuProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const onMouseDown = (e: React.MouseEvent) => {
    if (e.button === 0 && !isOpen) {
      props.onLeftMouseDown()
    } else {
      setIsOpen(x => !x)
    }
  }
  return <div className={"relative flex-1 flex items-stretch"} onContextMenu={(e) => e.preventDefault()}>
    {isOpen && <TaskContextMenuOptions task={props.task} project={props.project} close={() => setIsOpen(false)} />}
    {isOpen && <div className={"fixed inset-0 bg-[#00000008]"} onClick={() => setIsOpen(false)}></div>}
    <div className={`${props.className}`} onMouseDown={onMouseDown} onContextMenu={(e) => e.preventDefault()}>
      {props.children}
    </div>
  </div>
}
export const TaskContextMenuOptions: FC<{ task: Task, project?: Project, close: () => void }> = (props) => {
  const {setDay} = usePlanner()
  const {updateTaskInvoicedStatus} = useApi()
  const taskDetailsModal = useModal({size: 'lg', title: 'Details en leveranciers', body: <TaskDetailsModal task={props.task} />})
  const taskEditModal = useModal({title: 'Taak bewerken', body: <EditTaskModal task={props.task} />})
  const moveTaskModal = useModal({size: 'lg', title: 'Verplaats naar dag', body: <MoveTaskModal task={props.task} setDay={setDay} />})
  const copyTaskModal = useModal({size: 'lg', title: 'Kopiëer naar dag(en)', body: <CopyTaskModal task={props.task} setDay={setDay} />})
  const changeTaskColorModal = useModal({size: 'lg', title: 'Kleur aanpassen', body: <ChangeTaskColorModal task={props.task} />})
  const deleteTaskModal = useModal({title: 'Taak verwijderen', body: <DeleteTaskModal task={props.task} />})
  const toggleTaskInvoiced = async () => {
    await updateTaskInvoicedStatus(props.task, !props.task.isInvoiced)
    props.close()
  }
  return <div className={`border border-slate-300 z-[999] absolute bottom-0 right-0 translate-y-full bg-white text-slate-900 font-medium text-sm rounded-md shadow-lg p-1`}>
    <TaskContextMenuOption style={"normal"} icon={faPencil} label={'Taak bewerken'} onClick={async () => {taskEditModal.open(); props.close()}} />
    <TaskContextMenuOption style={"normal"} icon={faEuro} label={props.task.isInvoiced?'Markeer als \'Niet gefactureerd\'':'Markeer als \'Gefactureerd\''} onClick={toggleTaskInvoiced} />
    <TaskContextMenuOption style={"normal"} icon={faRightLeft} label={'Verplaats naar dag'} disabled={props.task.startAt === null} onClick={async () => {moveTaskModal.open(); props.close()}} />
    <TaskContextMenuOption style={"normal"} icon={faInfoCircle} label={'Details en leveranciers'} onClick={async () => {taskDetailsModal.open(); props.close()}} />
    <TaskContextMenuOption style={"normal"} icon={faCopy} label={'Kopiëer naar dag(en)'} disabled={props.task.startAt === null} onClick={async () => {copyTaskModal.open(); props.close()}} />
    <TaskContextMenuOption style={"normal"} icon={faPalette} label={'Kleur aanpassen'} onClick={async () => {changeTaskColorModal.open(); props.close()}} />
    <TaskContextMenuOption style={"danger"} icon={faTimes} label={'Taak verwijderen'} onClick={async () => {deleteTaskModal.open(); props.close()}} />
  </div>
}
export const TaskContextMenuOption: FC<{icon: IconDefinition, disabled?: boolean, label: string, style: "normal"|"danger", onClick: () => Promise<void>}> = ({icon, disabled, label, style, onClick}) => {
  const [isLoading, setIsLoading] = useState(false)
  const doClick = async () => {
    setIsLoading(true)
    await onClick()
    setIsLoading(false)
  }
  const styleClasses = {
    normal: "hover:bg-slate-100",
    danger: "text-red-600 hover:bg-red-100"
  }[style]
  const isDisabled = isLoading || disabled
  return <button className={`flex items-center w-full px-2 h-8 py-1 rounded-sm ${isDisabled ? 'text-slate-400' : styleClasses}`} disabled={isDisabled ?? false} onClick={doClick}>
    <FontAwesomeIcon icon={isLoading ? faCircleNotch : icon} spin={isLoading} className={"mr-2"} />
    <span className={"whitespace-nowrap"}>{label}</span>
  </button>
}