import React, {FC} from "react";

interface SelectProps {
  label: string
  options: {[key: string]: string}
  value: string
  onChange: (newValue: string) => void
}

export const Select: FC<SelectProps> = (props) =>  {
  return <label className={"flex flex-col text-sm font-medium text-blue-900 w-full max-w-md"}>
    {props.label}
    <select
      className={"border-2 border-slate-200 outline-blue-700 text-black rounded text-base font-normal px-2 mt-1 h-10 w-full"}
      onChange={(e) => props.onChange(e.target.value)}
      value={props.value}
    >
      {Object.entries(props.options).map(([key, option]) => {
        return <option value={key} key={key}>{option}</option>
      })}
    </select>
  </label>
}