import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Holiday} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";


export const DeleteGlobalHolidayModal: FC<{holiday: Holiday}> = (props) => {
  const {removeHoliday} = useApi()
  const confirm = async () => {
    await removeHoliday(props.holiday)
  }

  return <>
    <p className={""}>
      <strong>Let op!</strong> Je staat op het punt om de algemene vrije dag <strong>{props.holiday.name}</strong> te verwijderen.
    </p>
    <ModalFooter icon={fa.faTimes} text={"Verwijderen"} onSubmit={confirm} />
  </>
}
