import React, {FC, useState} from "react";
import {Card} from "../components/Card";
import {useApi} from "../api/APIContext";
import {Color, QuantityType, TASK_QUANTITY_TYPE_OTHER} from "../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleNotch, faEdit, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useModal} from "../components/layout/ModalProvider";
import {EditColorNameModal} from "../modals/EditColorNameModal";
import {Paragraph} from "../components/Paragraph";
import {AddOrEditQuantityTypeModal} from "../modals/AddOrEditQuantityTypeModal";
import {useThemes} from "../planning/util/theming";

export const Colors: FC = () => {

  const {colors, quantityTypes} = useApi()
  const addModal = useModal({title: "Type toevoegen", body: <AddOrEditQuantityTypeModal />})

  return (
    <main className={"flex-1 overflow-y-scroll max-h-[calc(100vh-4rem)]"}>
      <div className={"mx-6 my-8"}>
        <div className={"max-w-screen-lg mx-auto"}>
          <div className={"flex justify-between items-center h-12 mb-4"}>
            <h1 className={"flex items-center text-2xl font-bold"}>Kleuren en taak types</h1>
          </div>
          <Card title={"Taak types"}>
            <div className={"grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4"}>
              {quantityTypes.filter(q => q.enabled).map((qt, i) => <QuantityTypeCard quantityType={qt} key={i}/>)}
              <button className={"text-blue-800 text-sm hover:bg-blue-50 border border-blue-200 rounded-lg py-3 px-4 flex items-center justify-center"} onClick={() => addModal.open()}>
                <FontAwesomeIcon icon={faPlus} className={"mr-2"} />
                <span className={"font-medium"}>Type toevoegen</span>
              </button>
            </div>
            {quantityTypes.filter(q => !q.enabled).length > 0 && <>
              <hr className={"mt-6 -mx-6 border-slate-200"}/>
              <h2 className={"mb-2 mt-4 font-medium text-slate-800"}>Verborgen types</h2>
              <Paragraph>
                Types kunnen niet verwijderd worden, maar wel worden verborgen bij het aanmaken van nieuwe taken..
              </Paragraph>
              <div className={"grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4"}>
                {quantityTypes.filter(q => !q.enabled).map((qt, i) => <QuantityTypeCard quantityType={qt} key={i}/>)}
              </div>
            </>}
          </Card>
          <Card title={"Kleuren"}>
            <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"}>
              {colors.filter(c => c.enabled).map(color => (
                <ColorCard key={color.id} color={color}/>
              ))}
            </div>
            <hr className={"mt-6 -mx-6 border-slate-200"}/>
            <h2 className={"mb-2 mt-4 font-medium text-slate-800"}>Beschikbaar</h2>
            <Paragraph>
              Voeg de beschikbare kleuren toe om ze te gebruiken in de planning. Je kunt de kleuren ook een aangepaste naam geven.
            </Paragraph>
            <div className={"grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"}>
              {colors.filter(c => !c.enabled).map(color => (
                <ColorCard key={color.id} color={color}/>
              ))}
            </div>
          </Card>
        </div>
      </div>
    </main>
  );
}
function QuantityTypeCard(props: {quantityType: QuantityType}) {
  const {editQuantityType} = useApi()
  const {getTheme} = useThemes()
  const [isSaving, setIsSaving] = useState(false)
  const editModal = useModal({title: "Type bewerken", body: <AddOrEditQuantityTypeModal quantityType={props.quantityType} />})
  const toggleType = async () => {
    setIsSaving(true)
    await editQuantityType(props.quantityType.id, props.quantityType.name, !props.quantityType.enabled, props.quantityType.defaultColor, props.quantityType.descriptionTemplate, props.quantityType.hideArea)
    setIsSaving(false)
  }
  const defaultColor = props.quantityType.defaultColor ? getTheme(props.quantityType.defaultColor) : null
  return <div className={"text-slate-800 border border-slate-200 rounded-lg py-3 px-4 flex items-center"}>
    <div className={"flex-1"}>
      <div className={"text-lg font-medium"}>{props.quantityType.name}</div>
      <div className={"flex items-center"}>
        {defaultColor && <>
          <span className={`text-xs inline-block px-2 rounded-full mr-2 ${defaultColor.bg} ${defaultColor.text}`}>{defaultColor.name}</span>
        </>}
        <div
          className={"text-xs"}>{props.quantityType.descriptionTemplate.length < 2 && props.quantityType.descriptionTemplate[0] === "" ? 'Geen sjabloon' : `Sjabloon: ${props.quantityType.descriptionTemplate.length} taken`}</div>
      </div>
    </div>
    {props.quantityType.name === TASK_QUANTITY_TYPE_OTHER ? <>
      <button className={`ml-2 h-8 w-8 rounded bg-blue-50 text-blue-800`} onClick={() => editModal.open()}>
        <FontAwesomeIcon icon={faEdit}/></button>
    </> : <>
      {props.quantityType.enabled ? <>
        <button className={`ml-2 h-8 w-8 rounded bg-blue-50 text-blue-800`} onClick={() => editModal.open()}><FontAwesomeIcon icon={faEdit} /></button>
        <button className={`ml-2 h-8 w-8 rounded bg-blue-50 text-blue-800`} onClick={toggleType} disabled={isSaving}><FontAwesomeIcon icon={isSaving ? faCircleNotch : faTimes} spin={isSaving}/></button>
      </> : <>
        <button className={`ml-2 h-8 w-8 rounded bg-blue-50 text-blue-800`} onClick={toggleType} disabled={isSaving}><FontAwesomeIcon icon={isSaving ? faCircleNotch : faPlus} spin={isSaving}/></button>
      </>}
    </>}
  </div>
}

function ColorCard(props: { color: Color }) {
  const theme = (colorThemeMap[props.color.color] ?? colorThemeMap['slate'])[props.color.enabled ? 'enabled' : 'disabled']
  const {updateColor} = useApi()
  const [isSaving, setIsSaving] = useState(false)
  const editColorNameModal = useModal({title: "Kleur naam aanpassen", body: <EditColorNameModal color={props.color}/>})
  const toggleColor = async () => {
    setIsSaving(true)
    await updateColor(props.color.id, props.color.name, !props.color.enabled, props.color.color)
    setIsSaving(false)
  }
  return <div className={`${theme.bg} ${theme.text} rounded-lg py-3 px-4 flex items-center justify-between`}>
    <div className={"flex-1"}>
      <div className={"text-lg font-medium"}>{props.color.name}</div>
      {props.color.enabled ? <div className={"text-xs"}>Beschikbaar</div> : <div className={"text-xs"}>Niet in gebruik</div>}
    </div>
    {props.color.enabled ? <>
      <button className={`ml-2 h-8 w-8 rounded ${theme.buttonBg} ${theme.buttonText}`} onClick={() => editColorNameModal.open()}><FontAwesomeIcon
        icon={faEdit} /></button>
      <button className={`ml-2 h-8 w-8 rounded ${theme.buttonBg} ${theme.buttonText}`} onClick={toggleColor} disabled={isSaving}><FontAwesomeIcon
        icon={isSaving ? faCircleNotch : faTimes} spin={isSaving}/></button>
    </> : <>
      <button className={`ml-2 h-8 w-8 rounded ${theme.buttonBg} ${theme.buttonText}`} onClick={toggleColor} disabled={isSaving}><FontAwesomeIcon
        icon={isSaving ? faCircleNotch : faPlus} spin={isSaving}/></button>
    </>}
  </div>
}

interface Style {
  bg: string
  text: string
  buttonBg: string
  buttonText: string
}

const colorThemeMap: { [color: string]: { enabled: Style, disabled: Style } } = {
  'slate': {'disabled': {'bg': 'bg-slate-100', 'text': 'text-slate-600', 'buttonBg': 'bg-slate-200', 'buttonText': 'text-slate-700'}, 'enabled': {'bg': 'bg-slate-600', 'text': 'text-slate-50', 'buttonBg': 'bg-slate-500', 'buttonText': 'text-slate-50'}},
  'gray': {'disabled': {'bg': 'bg-gray-100', 'text': 'text-gray-600', 'buttonBg': 'bg-gray-200', 'buttonText': 'text-gray-700'}, 'enabled': {'bg': 'bg-gray-600', 'text': 'text-gray-50', 'buttonBg': 'bg-gray-500', 'buttonText': 'text-gray-50'}},
  'zinc': {'disabled': {'bg': 'bg-zinc-100', 'text': 'text-zinc-600', 'buttonBg': 'bg-zinc-200', 'buttonText': 'text-zinc-700'}, 'enabled': {'bg': 'bg-zinc-600', 'text': 'text-zinc-50', 'buttonBg': 'bg-zinc-500', 'buttonText': 'text-zinc-50'}},
  'neutral': {'disabled': {'bg': 'bg-neutral-100', 'text': 'text-neutral-600', 'buttonBg': 'bg-neutral-200', 'buttonText': 'text-neutral-700'}, 'enabled': {'bg': 'bg-neutral-600', 'text': 'text-neutral-50', 'buttonBg': 'bg-neutral-500', 'buttonText': 'text-neutral-50'}},
  'stone': {'disabled': {'bg': 'bg-stone-100', 'text': 'text-stone-600', 'buttonBg': 'bg-stone-200', 'buttonText': 'text-stone-700'}, 'enabled': {'bg': 'bg-stone-600', 'text': 'text-stone-50', 'buttonBg': 'bg-stone-500', 'buttonText': 'text-stone-50'}},
  'red': {'disabled': {'bg': 'bg-red-100', 'text': 'text-red-600', 'buttonBg': 'bg-red-200', 'buttonText': 'text-red-700'}, 'enabled': {'bg': 'bg-red-600', 'text': 'text-red-50', 'buttonBg': 'bg-red-500', 'buttonText': 'text-red-50'}},
  'orange': {'disabled': {'bg': 'bg-orange-100', 'text': 'text-orange-600', 'buttonBg': 'bg-orange-200', 'buttonText': 'text-orange-700'}, 'enabled': {'bg': 'bg-orange-600', 'text': 'text-orange-50', 'buttonBg': 'bg-orange-500', 'buttonText': 'text-orange-50'}},
  'amber': {'disabled': {'bg': 'bg-amber-100', 'text': 'text-amber-600', 'buttonBg': 'bg-amber-200', 'buttonText': 'text-amber-700'}, 'enabled': {'bg': 'bg-amber-600', 'text': 'text-amber-50', 'buttonBg': 'bg-amber-500', 'buttonText': 'text-amber-50'}},
  'yellow': {'disabled': {'bg': 'bg-yellow-100', 'text': 'text-yellow-600', 'buttonBg': 'bg-yellow-200', 'buttonText': 'text-yellow-700'}, 'enabled': {'bg': 'bg-yellow-600', 'text': 'text-yellow-50', 'buttonBg': 'bg-yellow-500', 'buttonText': 'text-yellow-50'}},
  'lime': {'disabled': {'bg': 'bg-lime-100', 'text': 'text-lime-600', 'buttonBg': 'bg-lime-200', 'buttonText': 'text-lime-700'}, 'enabled': {'bg': 'bg-lime-600', 'text': 'text-lime-50', 'buttonBg': 'bg-lime-500', 'buttonText': 'text-lime-50'}},
  'green': {'disabled': {'bg': 'bg-green-100', 'text': 'text-green-600', 'buttonBg': 'bg-green-200', 'buttonText': 'text-green-700'}, 'enabled': {'bg': 'bg-green-600', 'text': 'text-green-50', 'buttonBg': 'bg-green-500', 'buttonText': 'text-green-50'}},
  'emerald': {'disabled': {'bg': 'bg-emerald-100', 'text': 'text-emerald-600', 'buttonBg': 'bg-emerald-200', 'buttonText': 'text-emerald-700'}, 'enabled': {'bg': 'bg-emerald-600', 'text': 'text-emerald-50', 'buttonBg': 'bg-emerald-500', 'buttonText': 'text-emerald-50'}},
  'teal': {'disabled': {'bg': 'bg-teal-100', 'text': 'text-teal-600', 'buttonBg': 'bg-teal-200', 'buttonText': 'text-teal-700'}, 'enabled': {'bg': 'bg-teal-600', 'text': 'text-teal-50', 'buttonBg': 'bg-teal-500', 'buttonText': 'text-teal-50'}},
  'cyan': {'disabled': {'bg': 'bg-cyan-100', 'text': 'text-cyan-600', 'buttonBg': 'bg-cyan-200', 'buttonText': 'text-cyan-700'}, 'enabled': {'bg': 'bg-cyan-600', 'text': 'text-cyan-50', 'buttonBg': 'bg-cyan-500', 'buttonText': 'text-cyan-50'}},
  'sky': {'disabled': {'bg': 'bg-sky-100', 'text': 'text-sky-600', 'buttonBg': 'bg-sky-200', 'buttonText': 'text-sky-700'}, 'enabled': {'bg': 'bg-sky-600', 'text': 'text-sky-50', 'buttonBg': 'bg-sky-500', 'buttonText': 'text-sky-50'}},
  'blue': {'disabled': {'bg': 'bg-blue-100', 'text': 'text-blue-600', 'buttonBg': 'bg-blue-200', 'buttonText': 'text-blue-700'}, 'enabled': {'bg': 'bg-blue-600', 'text': 'text-blue-50', 'buttonBg': 'bg-blue-500', 'buttonText': 'text-blue-50'}},
  'indigo': {'disabled': {'bg': 'bg-indigo-100', 'text': 'text-indigo-600', 'buttonBg': 'bg-indigo-200', 'buttonText': 'text-indigo-700'}, 'enabled': {'bg': 'bg-indigo-600', 'text': 'text-indigo-50', 'buttonBg': 'bg-indigo-500', 'buttonText': 'text-indigo-50'}},
  'violet': {'disabled': {'bg': 'bg-violet-100', 'text': 'text-violet-600', 'buttonBg': 'bg-violet-200', 'buttonText': 'text-violet-700'}, 'enabled': {'bg': 'bg-violet-600', 'text': 'text-violet-50', 'buttonBg': 'bg-violet-500', 'buttonText': 'text-violet-50'}},
  'purple': {'disabled': {'bg': 'bg-purple-100', 'text': 'text-purple-600', 'buttonBg': 'bg-purple-200', 'buttonText': 'text-purple-700'}, 'enabled': {'bg': 'bg-purple-600', 'text': 'text-purple-50', 'buttonBg': 'bg-purple-500', 'buttonText': 'text-purple-50'}},
  'fuchsia': {'disabled': {'bg': 'bg-fuchsia-100', 'text': 'text-fuchsia-600', 'buttonBg': 'bg-fuchsia-200', 'buttonText': 'text-fuchsia-700'}, 'enabled': {'bg': 'bg-fuchsia-600', 'text': 'text-fuchsia-50', 'buttonBg': 'bg-fuchsia-500', 'buttonText': 'text-fuchsia-50'}},
  'pink': {'disabled': {'bg': 'bg-pink-100', 'text': 'text-pink-600', 'buttonBg': 'bg-pink-200', 'buttonText': 'text-pink-700'}, 'enabled': {'bg': 'bg-pink-600', 'text': 'text-pink-50', 'buttonBg': 'bg-pink-500', 'buttonText': 'text-pink-50'}},
  'rose': {'disabled': {'bg': 'bg-rose-100', 'text': 'text-rose-600', 'buttonBg': 'bg-rose-200', 'buttonText': 'text-rose-700'}, 'enabled': {'bg': 'bg-rose-600', 'text': 'text-rose-50', 'buttonBg': 'bg-rose-500', 'buttonText': 'text-rose-50'}},
}
