import React, {FC} from "react";
import {useKeycloak} from "@react-keycloak/web";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket, faBoxOpen,
  faCalendar, faCalendarMinus,
  faChevronLeft,
  faChevronRight,
  faCode, faDolly, faPaintRoller,
  faPeopleGroup, faPerson, faReceipt,
  IconDefinition
} from "@fortawesome/free-solid-svg-icons";
import {usePersistentState} from "../../util/usePersistentState";

const navigation: {link: string, icon: IconDefinition, text: string}[] = [
  {
    link: '/',
    text: 'Planning',
    icon: faCalendar,
  },
  {
    link: '/bestellingen',
    text: 'Bestellingen',
    icon: faDolly,
  },
  {
    link: '/workorders',
    text: 'Werkbonnen',
    icon: faReceipt,
  },
  {
    link: '/personeel',
    text: 'Personeel',
    icon: faPerson,
  },
  {
    link: '/teams',
    text: 'Teams',
    icon: faPeopleGroup,
  },
  {
    link: '/holidays',
    text: 'Vrije dagen',
    icon: faCalendarMinus,
  },
  {
    link: '/leveranciers',
    text: 'Leveranciers',
    icon: faBoxOpen,
  },
  {
    link: '/kleuren',
    text: 'Kleuren',
    icon: faPaintRoller,
  },
  ...(import.meta.env.MODE === "development" ? [
    {
      link: '/instellingen',
      text: 'DevSettings',
      icon: faCode,
    },
  ] : []),
]
export const AuthenticatedLayout: FC<{children: React.ReactNode}> = ({children}) => {
  const {keycloak} = useKeycloak()
  const [isCollapsed, setIsCollapsed] = usePersistentState<boolean>('collapse_sidebar', false)
  const linkStyle = (props: {isActive: boolean, isPending: boolean}) => `h-12 flex items-center border-l-4 ${props.isActive ? 'border-blue-900 bg-blue-50 text-black' : 'border-transparent text-gray-500  hover:bg-gray-50 hover:border-blue-900'} px-5 font-medium`
  return <div className={"flex flex-col border-red-500 min-h-screen bg-gray-50"}>
    <nav className={"bg-blue-950 text-white h-16 flex items-stretch justify-between"}>
      <div className={"flex justify-center items-center px-6"}>
        <img src={"traasbg.svg"} alt={"Traas logo"} className={"h-8"}/>
        <span className={"text-xl font-bold mx-3 opacity-75"}>&middot;</span>
        <h1 className={"font-medium text-xl"}>
          Planning & werkbonnen
        </h1>
      </div>
      <button className={"flex items-center hover:bg-[#0002] px-6"} onClick={() => keycloak.logout()}>
        <div className={"flex flex-col items-end justify-center mr-4"}>
          <small className={"leading-tight opacity-90"}>Ingelogd als {keycloak.idTokenParsed?.email ?? '-'}</small>
          <div className={"leading-tight"}>Uitloggen</div>
        </div>
        <FontAwesomeIcon icon={faArrowRightFromBracket} />
      </button>
    </nav>
    <div className={"flex-1 flex items-stretch"}>
      <aside className={`bg-white ${isCollapsed ? 'w-16' : 'w-48'} border-r border-gray-200 flex flex-col justify-between pt-8`}>
        {/* Navigation */}
        <div className={'flex flex-col'}>
          {navigation.map((item, i) => <NavLink to={item.link} className={linkStyle} key={i}>
            <span className={`w-5 text-center ${isCollapsed ? '' : 'mr-3'}`}>
              <FontAwesomeIcon icon={item.icon} />
            </span>
            {!isCollapsed && <span>{item.text}</span>}
          </NavLink> )}
        </div>
        <button className={"hover:bg-gray-50 h-12"} onClick={() => setIsCollapsed(x => !x)}>
          <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} />
        </button>
      </aside>
      {children}
    </div>
  </div>
}
