import React, {FC} from "react";
import {Employee} from "../../api/dto";
import {useModal} from "../../components/layout/ModalProvider";
import {UpdateEmployeeModal} from "../../modals/UpdateEmployeeModal";
import {DisableLoginModal} from "../../modals/DisableLoginModal";
import {Button} from "../../components/form/Button";
import {faAt, faPlus, faTimes, faUserSecret} from "@fortawesome/free-solid-svg-icons";

export const LoginEditor: FC<{employee: Employee}> = ({employee}) => {
    const activateLoginModal = useModal({title: 'Activeer medewerker login', body: <UpdateEmployeeModal employee={employee} updateName={false} updateEmail={true} updatePassword={true} />})
    const changeEmailModal = useModal({title: 'Email wijzigen', body: <UpdateEmployeeModal employee={employee} updateName={false} updateEmail={true} updatePassword={false} />})
    const changePasswordModal = useModal({title: 'Wachtwoord wijzigen', body: <UpdateEmployeeModal employee={employee} updateName={false} updateEmail={false} updatePassword={true} />})
    const disableLoginModal = useModal({title: 'Login intrekken', body: <DisableLoginModal employee={employee} />})
    if (! employee.email) {
        return <div>
            <p className={"text-slate-600 mb-3"}>Deze medewerker kan niet inloggen in de app.</p>
            <Button type={'secondary'} size={'sm'} text={'Zet inloggen aan'} icon={faPlus} onClick={() => activateLoginModal.open()} />
        </div>
    }
    return <div className={'flex flex-col items-stretch space-y-1'}>
        <Button type={'secondary'} size={'sm'} text={'Wijzig e-mail'} icon={faAt} onClick={() => changeEmailModal.open()} />
        <Button type={'secondary'} size={'sm'} text={'Reset wachtwoord'} icon={faUserSecret} onClick={() => changePasswordModal.open()} />
        <Button type={"danger"} size={'sm'} text={"Login intrekken"} icon={faTimes} onClick={() => disableLoginModal.open()} />
    </div>
}