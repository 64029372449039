import {FC, useEffect, useMemo, useState} from "react";
import {usePlanner} from "./PlannerContext";
import moment from "moment";
import {useApi} from "../api/APIContext";
import {Order, Project, Team, Workorder} from "../api/dto";
import {Button, IconButton} from "../components/form/Button";
import {
  faCheckSquare,
  faLocation,
  faLocationPin,
  faPeopleGroup,
  faPerson, faPhone,
  faPlus,
  faSave,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {AddOrderModal} from "../modals/AddOrderModal";
import {useModal} from "../components/layout/ModalProvider";
import {DeleteOrderModal} from "../modals/DeleteOrderModal";
import React from "react";
import {OrderAutocomplete} from "../components/form/Autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {WorkorderDetailsModal} from "../modals/WorkorderDetailsModal";

export const WorkorderOverview: FC<{compact: boolean}> = (props) => {
  const {day} = usePlanner()
  const {workorders, projects, teams} = useApi()
  const monday = moment(day).set('day', 1)
  const days = [
    monday,
    monday.clone().add(1, 'day'),
    monday.clone().add(2, 'day'),
    monday.clone().add(3, 'day'),
    monday.clone().add(4, 'day'),
    monday.clone().add(5, 'day'),
    monday.clone().add(6, 'day'),
  ]
  const teamsMap = useMemo(() => {
    return teams.reduce((acc, t) => {
      acc[t.id] = t
      return acc
    }, {} as {[key: string]: Team})
  }, [teams]);
  return <div className={"max-w-screen-2xl mx-auto"}>
    {days.map((d, i) => {
      const dayOrders = workorders.filter(o => moment(o.startAt).isSame(d, 'day'))
      return <div key={i} className={"flex-1"}>
        <h1 className={"font-medium text-lg mb-4"}>{d.toDate().toLocaleDateString('nl', {weekday: 'long', day: 'numeric', month: 'long'})}</h1>
        <div className={'mt-2 mb-8 grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6'}>
          {dayOrders.map((workorder, i) => {
            return <WorkorderOverviewCard compact={props.compact} workorder={workorder} key={i} team={teamsMap[workorder.teamId ?? '']} />
          })}
        </div>
      </div>
    })}
  </div>
}

const WorkorderOverviewCard: FC<{workorder: Workorder, team?: Team, compact: boolean}> = props => {
  const detailsModal = useModal({title: "Werkbon", size: 'xl', body: <WorkorderDetailsModal order={props.workorder} />})
  return <div className={`bg-white px-4 py-3 border ${props.workorder.isFinished ? 'border-green-700' : 'border-slate-200'} rounded relative flex flex-col items-stretch`}>
    {props.workorder.isFinished && <>
      <div
        className={`h-5 absolute -top-3 inset-x-4 bg-green-700 rounded border border-white text-white px-1 flex items-center justify-center text-xs`}>
        <FontAwesomeIcon icon={faCheckSquare} className={"mr-1"}/>
        <span className={"font-medium"}>AFGEROND</span>
      </div>
    </>}
    <div className={`${props.workorder.isFinished ? 'opacity-50' : ''} flex-1`}>
      <div className={"flex items-center justify-between"}>
        <div className={"font-medium text-sm"}>Project #{props.workorder.projectNumber}</div>
        {props.workorder.projectDeliveryCity && <div>
          <div className={'text-sm text-blue-800 flex items-center space-x-2'}>
            <FontAwesomeIcon icon={faLocationPin}/>
            <div>{props.workorder.projectDeliveryCity}</div>
          </div>
        </div>}
      </div>
      <div className={"text-lg font-medium my-3"}>
        {props.workorder.projectDescription ?? "Overig project"}
      </div>
      {props.workorder.quantityAmount !== null && props.workorder.quantityTypeName !== null && <div>
        {props.workorder.quantityAmount} m² {props.workorder.quantityTypeName}
      </div>}
      <div>
        {props.workorder.description}
      </div>
      {props.team && <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
        <div className={"font-medium text-sm"}>Team</div>
        <div className={"font-medium text-blue-900 ml-2"}>
          <FontAwesomeIcon icon={faPeopleGroup} className={"mr-2"}/>{props.team.name}
        </div>
      </div>}
      {!props.compact && <>
        {(props.workorder.projectRemark || props.workorder.workorderRemark) &&
          <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
            <div className={"font-medium text-sm"}>Opmerkingen</div>
            <div className={"ml-2 my-1 text-slate-700"}>
              <div>{props.workorder.workorderRemark}</div>
              <div>{props.workorder.projectRemark}</div>
            </div>
          </div>}
        {props.workorder.projectDeliveryName && <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
          <div className={"font-medium text-sm"}>Contactpersoon</div>
          <div className={"font-medium text-blue-900 ml-2"}><FontAwesomeIcon icon={faPerson}
                                                                             className={"mr-2"}/>{props.workorder.projectDeliveryName}
          </div>
        </div>}
        {props.workorder.projectDeliveryPhone && <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
          <div className={"font-medium text-sm"}>Contactpersoon (telefoon)</div>
          <div className={"font-medium text-blue-900 ml-2"}><FontAwesomeIcon icon={faPhone}
                                                                             className={"mr-2"}/>{props.workorder.projectDeliveryPhone}
          </div>
        </div>}
        {props.workorder.projectDeliveryAddress && props.workorder.projectDeliveryZipCode && props.workorder.projectDeliveryCity &&
          <div className={'border border-slate-200 px-3 py-1 rounded my-3'}>
            <div className={"font-medium text-sm"}>Werkadres</div>
            <div className={"font-medium text-blue-900 ml-2 flex items-center"}><FontAwesomeIcon icon={faLocationPin}
                                                                                                 className={"mr-2"}/>
              <div>
                {props.workorder.projectDeliveryAddress}<br/>
                {props.workorder.projectDeliveryZipCode},&nbsp;{props.workorder.projectDeliveryCity}
              </div>
            </div>
          </div>}
      </>}
    </div>
    {props.workorder.isFinished && <Button type={'primary'} size={'md'} text={'Bekijk Opmerkingen & Fotos'} onClick={() => detailsModal.open()} />}
  </div>
}
