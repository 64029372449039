import React, {FC, useMemo} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";
import {Input} from "../components/form/Input";
import {Project} from "../api/dto";
import {Autocomplete} from "../components/form/Autocomplete";
import {ValidationError} from "../api/api";


export const AddOrderModal: FC<{date: Date, project?: Project}> = (props) => {
  const {createOrder, projects} = useApi()
  const [projectId, setProjectId] = React.useState<string>()
  const [text, setText] = React.useState("")
  // const [lastName, setLastName] = React.useState("")
  // const [email, setEmail] = React.useState("")
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    console.log(projectId, Number(projectId))
    if (!projectId) {
      setErrors(["Project is verplicht"])
      return
    }
    await createOrder(Number(projectId), props.date, text)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }
  const sortedProjects = useMemo(() => {
    const projectsMap = projects.reduce<{ [key: string]: string }>((acc, p) => {
      acc[p.id] = `#${p.projectNumber} - ${p.description}`;
      return acc;
    }, {});

    return Object.entries(projectId ? projectsMap : { '_': 'Selecteer Project', ...projectsMap })
      .sort((a, b) => b[0].localeCompare(a[0]));
  }, [projects, projectId]);


  return <div className={"space-y-2"}>
    <Autocomplete label={'Project'} options={sortedProjects} value={projectId ?? '_'} onChange={setProjectId} />
    <Input label={'Omschrijving'} value={text} onChange={setText} focus={true} type={'text'} />
    {/*<Input label={'E-mailadres (optioneel)'} value={email ?? ""} onChange={setEmail} type={'email'} />*/}
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter icon={fa.faPlus} text={"Opslaan"} onSubmit={confirm} />
  </div>
}
