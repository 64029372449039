import React, {FC, MouseEvent, Key, useEffect, useMemo, useRef, useCallback, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";

export type KeyProperties<T> = {
  [P in keyof T]: T[P] extends Key ? P : never;
}[keyof T]

interface AutocompleteProps {
  label: string
  options: [key: string, value: string][]
  value: string
  onChange: (newValue: string) => void
  focus?: true
}

export const Autocomplete: FC<AutocompleteProps> = (props) =>  {
  const [search, setSearch] = React.useState("")
  const [hasFocus, setHasFocus] = React.useState(false)

  const input = useRef<HTMLInputElement>(null)

  const selectedOptionText = useMemo(() => {
    const selectedOption = props.options.find(option => option[0] === props.value);
    return selectedOption ? selectedOption[1] : '';
  }, [props.options, props.value])

  const options = useMemo(() => props.options.filter(option => {
    return option[1].toLowerCase().includes(search.toLowerCase());
  }), [props.options, search]);

  const selectOption = (key: string) => {
    const selectedOption = props.options.find(option => option[0] === key);
    if (selectedOption) {
      setSearch(selectedOption[1]);
      props.onChange(key);
      setHasFocus(false);
      input.current?.blur();
    }
  };

  const [selectedPosition, setSelectedPosition] = React.useState<number>(0)
  const [showAll, setShowAll] = React.useState(false)
  useEffect(() => {
    if (! options[selectedPosition]) {
      setSelectedPosition(0)
    }
  }, [selectedPosition, options])

  const onItemClick = (event: MouseEvent<HTMLButtonElement>, key: string) => {
    event.preventDefault()
    selectOption(key)
  }
  const onInputFocus = () => {
    setHasFocus(true)
    setSearch("")
  }
  const onInputBlur = () => {
    setHasFocus(false)
  }

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault()
      if (selectedPosition < options.length - 1) {
        setSelectedPosition(selectedPosition + 1)
      }
    }
    if (event.key === "ArrowUp") {
      event.preventDefault()
      if (selectedPosition > 0) {
        setSelectedPosition(selectedPosition - 1)
      }
    }
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault()
      selectOption(options[selectedPosition][0])
    }
  }
  const hidesSome = (options.length > 100 && !showAll)
  const displayOptions = hidesSome ? options.slice(0, 100) : options

  return <label className={"flex flex-col text-sm font-medium text-blue-900 w-full max-w-md"}>
    {props.label}
    <div className={"relative mt-1"}>
      <input
        ref={input}
        autoFocus={props.focus}
        onFocus={() => onInputFocus()}
        onBlur={() => onInputBlur()}
        onKeyDown={(e) => onInputKeyDown(e)}
        className={"border-2 border-slate-200 outline-blue-700 text-black rounded text-base font-normal px-2 h-10 w-full"}
        onChange={(e) => setSearch(e.target.value)}
        value={hasFocus ? search : selectedOptionText}
      />

      {/* Chevron icon*/}
      <div className={"absolute top-0 right-2 h-full flex items-center justify-end"}>
        <FontAwesomeIcon icon={fa.faChevronDown} className={"text-xs text-blue-900"} />
      </div>

      {hasFocus && <div className={"absolute top-10 left-0 w-full text-black bg-white rounded shadow z-10 max-h-[220px] overflow-y-scroll"}>
        <div className={"flex flex-col items-stretch"}>
          {displayOptions.map((option, index) => (
            <button
              key={index}
              className={`h-10 w-full px-4 ${index === selectedPosition ? "bg-slate-100" : ""} hover:bg-slate-100 ${option[0] === props.value ? "text-blue-800" : ''} text-left`}
              onMouseDown={(e) => onItemClick(e, option[0])}
            >
              {option[1]}
            </button>
          ))}
          {hidesSome && <button className={`h-10 w-full px-4 hover:bg-slate-100 text-left`}
                  onMouseDown={() => setShowAll(true)}>
            Toon meer ({options.length - 100})
          </button>}
        </div>
      </div>}
    </div>
  </label>
}

interface ObjectAutocompleteProps<T> {
  label: string
  options: T[]
  value: T | null
  onChange: (newValue: T) => void
  displayItem: (obj: T) => string
  idProperty: KeyProperties<T>
}

export const ObjectAutocomplete = function<T>(props: ObjectAutocompleteProps<T>): JSX.Element {
  const getDisplayValue = (option: T) => {
    return props.displayItem(option)
  }
  const getKey = (option: T): string => {
    return option[props.idProperty] as unknown as string;
  }

  const options = useMemo(() => {
    return props.options.reduce<[string, string][]>((list, option) => {
      const key = getKey(option);
      list.push([key, getDisplayValue(option)]);
      return list;
    }, []);
  }, [props.options]);

  const onChange = (newKey: string) => {
    const value = props.options.find(option => getKey(option) === newKey);
    if (value) {
      props.onChange(value);
    }
  };

  if (!props.value) {
    return <AutocompletePlaceholder label={props.label} />;
  }
  return <Autocomplete label={props.label} options={options} value={getKey(props.value) as string} onChange={onChange} />
}

const AutocompletePlaceholder: FC<{label: string}> = (props) => {
  return <div className={"flex flex-col text-sm font-medium text-blue-900 w-full max-w-md"}>
    {props.label}
    <div className={"relative mt-1"}>
      <div className={"border-2 border-slate-200 outline-blue-700 text-black rounded text-base font-normal px-2 h-10 w-full"} />
      {/* Chevron icon*/}
      <div className={"absolute top-0 right-2 h-full flex items-center justify-end"}>
        <FontAwesomeIcon icon={fa.faChevronDown} className={"text-xs text-blue-900"} />
      </div>
    </div>
  </div>
}

interface OrderAutocompleteProps {
  options: string[];
  value: string;
  onChange: (newValue: string) => void;
  focus?: true;
  onBlur?: (value: string) => Promise<void>;
}

export const OrderAutocomplete: FC<OrderAutocompleteProps> = (props) => {
  // const [search, setSearch] = React.useState<string>(props.value);
  const [hasFocus, setHasFocus] = React.useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false)
  const [selectedPosition, setSelectedPosition] = React.useState<number>(0)
  const [showAll, setShowAll] = React.useState(false)
  const input = useRef<HTMLInputElement>(null);

  const filteredOptions = useMemo(() => {
    return props.options.filter((option) => {
      return option !== '_' && option.toLowerCase().includes(props.value.toLowerCase());
    });
  }, [props.options, props.value]);

  useEffect(() => {
    if (filteredOptions.length === 0) {
      return
    }
    if (! filteredOptions[selectedPosition]) {
      setSelectedPosition(0)
    }
  }, [selectedPosition, filteredOptions])

  const handleBlur = useCallback(async() => {
    setIsSaving(true)
    await props.onBlur?.(props.value)
    setIsSaving(false)
  }, [props.value])

  const selectOption = useCallback(  (option?: string) => {
    if (option === undefined) {
      props.onChange(props.value);
    } else {
      props.onChange(option);
    }
    setHasFocus(false);
  }, [props.onChange, props.value]);

  const onInputFocus = useCallback(() => {
    setHasFocus(true);
    props.onChange('');
  }, [])

  const onInputBlur = useCallback(() => {
    setHasFocus(false);
    handleBlur()
  }, [handleBlur])

  const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowDown") {
      event.preventDefault()
      if (selectedPosition < filteredOptions.length - 1) {
        setSelectedPosition(selectedPosition + 1)
      }
    }
    if (event.key === "ArrowUp") {
      event.preventDefault()
      if (selectedPosition > 0) {
        setSelectedPosition(selectedPosition - 1)
      }
    }
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault()
      selectOption(filteredOptions[selectedPosition])
    }
  }

  const onItemClick = (event: MouseEvent<HTMLButtonElement>, key: string) => {
    event.preventDefault()
    selectOption(key)
  }

  return (
    <label className="flex flex-col text-sm font-medium text-blue-900 w-full max-w-md">
      <div className="relative mt-1">
        <input
          ref={input}
          autoFocus={props.focus}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          onKeyDown={onInputKeyDown}
          className="border-2 border-slate-200 outline-blue-700 text-black rounded text-base font-normal px-2 h-8 w-full"
          onChange={(e) => props.onChange(e.target.value)}
          value={props.value}
        />
        {isSaving && <div className={"absolute top-0 right-2 h-full flex items-center justify-end"}>
          <FontAwesomeIcon icon={fa.faCircleNotch} className={"text-xs text-blue-900 animate-spin"}/>
        </div>}
        {hasFocus && (
          <div
            className="absolute top-10 left-0 w-full text-black bg-white rounded shadow z-10 max-h-[220px] overflow-y-scroll">
            <div className="flex flex-col items-stretch">
              {filteredOptions.map((option, index) => (
                <button
                  key={option}
                  className={`h-10 w-full px-4 ${index === selectedPosition ? 'bg-slate-100' : ''} hover:bg-slate-100 ${
                    option === props.value ? 'text-blue-800' : ''
                  } text-left`}
                  onMouseDown={(e) => onItemClick(e, option)}
                >
                  {option}
                </button>
              ))}
              {!showAll && filteredOptions.length > 100 && (
                <button
                  className="h-10 w-full px-4 hover:bg-slate-100 text-left"
                  onClick={() => setShowAll(true)}
                >
                  Show more ({filteredOptions.length - 100})
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </label>
  );
};