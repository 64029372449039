import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useNavigate} from "react-router-dom";
import {Employee} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter, useModalControls} from "../components/layout/ModalProvider";
import {Button} from "../components/form/Button";


export const TeamHistoryModal: FC<{employee: Employee}> = (props) => {
  const {close} = useModalControls()
  return <>
    <p className={""}>
      Deze medewerker heeft tot nu toe nog geen deel uitgemaakt van een team.
    </p>
    <div className={"flex flex-col items-stretch py-4"}>
      <Button type={'secondary'} size={'md'} text={'Sluiten'} onClick={close} />
    </div>
  </>
}
