import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Holiday, Team} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";


export const DeleteTeamModal: FC<{team: Team, date: Date}> = (props) => {
  const {deleteTeam} = useApi()
  const confirm = async () => {
    await deleteTeam(props.team, props.date)
  }

  return <>
    <p className={""}>
      <strong>Let op!</strong> Je staat op het punt om het team <strong>{props.team.name}</strong> te archiveren.
    </p>
    <ModalFooter icon={fa.faTimes} text={"Verwijderen"} onSubmit={confirm} />
  </>
}
