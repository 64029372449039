import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";
import { Input } from "../components/form/Input";
import {ValidationError} from "../api/api";


export const AddEmployeeModal: FC<{}> = () => {
  const {addEmployee} = useApi()
  const [firstName, setFirstName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    await addEmployee(email.length > 0 ? {firstName, lastName, email} : {firstName, lastName, email: null})
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <div className={"space-y-2"}>
    <div className={"grid grid-cols-2 gap-2"}>
      <Input label={'Voornaam'} value={firstName} onChange={setFirstName} focus={true} type={'text'} />
      <Input label={'Achternaam'} value={lastName} onChange={setLastName} type={'text'} />
    </div>
    <Input label={'E-mailadres (optioneel)'} value={email ?? ""} onChange={setEmail} type={'email'} />
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter icon={fa.faPlus} text={"Opslaan"} onSubmit={confirm} />
  </div>
}
