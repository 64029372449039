import React, {FC} from "react";
import {Card} from "../components/Card";
import {usePersistentState} from "../util/usePersistentState";
import {Paragraph} from "../components/Paragraph";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faToggleOff, faToggleOn} from "@fortawesome/free-solid-svg-icons";
import {useKeycloak} from "@react-keycloak/web";

export const Instellingen: FC = () => {
  const [hints, setHints] = usePersistentState<boolean>('settings.keyboard_hints_enabled', false)
  const toggle = () => {
    setHints(x => !x)
    window.location.reload()
  }
  const copy = (text: string) => {
    navigator.clipboard.writeText(text)
  }
  const {keycloak} = useKeycloak()

  return (
    <main className={"flex-1 overflow-y-scroll max-h-[calc(100vh-4rem)]"}>
      <div className={"mx-6 my-8"}>
        <div>
          <h1 className={"h-12 flex items-center text-2xl mb-6 font-bold"}>Instellingen</h1>
          <Card title={'Toon sneltoetsen'}>
            <Paragraph>
                Veel handelingen in de applicatie zijn sneller te doen door het gebruik van sneltoetsen.
                De mogelijke sneltoetsen kunnen worden weergegeven, maar dit kun je ook uitzetten.
              </Paragraph>
              <button className={"bg-blue-900 text-white font-medium rounded px-4 py-2"} onClick={() => toggle()}><FontAwesomeIcon icon={hints ? faToggleOn : faToggleOff} className={"mr-1"} /> Zet sneltoetshints {hints ? 'uit' : 'aan'}</button>
            </Card>
            <Card title={'Debug-gegevens voor ontwikkelaars'}>
              <Paragraph>
                Deze gegevens zijn alleen relevant voor ontwikkelaars. Als je geen ontwikkelaar bent, kun je deze data negeren.
              </Paragraph>
              <div className={"rounded mb-3 bg-blue-50 p-2 font-mono text-sm break-all"}>{ keycloak.token }</div>
              <button className={"bg-blue-900 text-white font-medium rounded px-4 py-2"} onClick={() => copy(keycloak.token ?? "")}><FontAwesomeIcon icon={faCopy} className={"mr-1"} /> Copy</button>
            </Card>
          </div>
      </div>
    </main>
  );
}
