import React, {FC} from "react";
import {EmployeeManager} from "../personel/components/EmployeeManager";

export const Personeel: FC = () => {

  return (
    <main className={"flex-1 overflow-y-scroll max-h-[calc(100vh-4rem)]"}>
      <div className={"mx-6 my-8"}>
        <div className={"max-w-screen-2xl mx-auto"}>
          <EmployeeManager />
        </div>
      </div>
    </main>
  );
}









