import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Employee} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";
import { Input } from "../components/form/Input";
import {ValidationError} from "../api/api";


export const UpdateEmployeeModal: FC<{employee: Employee, updateName: boolean, updateEmail: boolean, updatePassword: boolean}> = (props) => {
  const {updateEmployee, updateEmployeePassword} = useApi()
  const [firstName, setFirstName] = React.useState(props.employee.firstName)
  const [lastName, setLastName] = React.useState(props.employee.lastName)
  const [email, setEmail] = React.useState(props.employee.email)
  const [password, setPassword] = React.useState("")
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    const fields = {...props.employee}
    if (props.updateName) {
      fields.firstName = firstName
      fields.lastName = lastName
    }
    if (props.updateEmail) {
      fields.email = email
    }
    await updateEmployee(fields)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
    if (props.updatePassword) {
      await updateEmployeePassword(props.employee, password)
        .catch(e => {
          if (e instanceof ValidationError) {
            setErrors(Object.values(e.errors).flat())
          }
          throw e
        })
    }
  }

  return <div className={"space-y-2"}>
    {props.updateName && <div className={"grid grid-cols-2 gap-2"}>
      <Input label={'Voornaam'} value={firstName} onChange={setFirstName} focus={true} type={'text'} />
      <Input label={'Achternaam'} value={lastName} onChange={setLastName} type={'text'} />
    </div>}
    {props.updateEmail && <>
      <Input label={'E-mailadres'} value={email ?? ""} onChange={setEmail} type={'email'} />
    </>}
    {props.updatePassword && <>
      <Input label={'Wachtwoord'} value={password} onChange={setPassword} type={'password'} />
    </>}
    {errors.length > 0 && <div className={"text-red-600 mt-2 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter icon={fa.faSave} text={"Wijzigingen opslaan"} onSubmit={confirm} />
  </div>
}
