import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Holiday, Task, Team} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";


export const SendWorkordersModal: FC<{tasks:Task[]}> = (props) => {
  const {sendWorkorders} = useApi()
  const confirm = async () => {
    await sendWorkorders(props.tasks)
  }

  return <>
    <p className={""}>
      <strong>Let op!</strong> Je staat op het punt om <strong>{props.tasks.length} {props.tasks.length === 1 ? 'werkbon' : 'werkbonnen'}</strong> te versturen.
      Deze zijn hierna zichtbaar voor de medewerkers van de desbetreffende teams.
    </p>
    <ModalFooter icon={fa.faPaperPlane} text={"Verzenden"} onSubmit={confirm} />
  </>
}
