import {useApi} from "../../api/APIContext";
import React, {FC, useCallback, useEffect, useMemo, useState} from "react";
import {Employee, Team, TeamMembership} from "../../api/dto";
import {Button, IconButton} from "../../components/form/Button";
import {
  faArchive,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faInfo,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useModal} from "../../components/layout/ModalProvider";
import {EditTeamModal} from "../../modals/EditTeamModal";
import {DeleteTeamModal} from "../../modals/DeleteTeamModal";
import {TinyEmployeeAvatar} from "../../personel/components/EmployeeAvatar";
import {SwitchTeamModal} from "../../modals/SwitchTeamModal";
import {Input} from "../../components/form/Input";
import moment from "moment";
import {Select} from "../../components/form/Select";
import {Toggle} from "../../components/form/Toggle";

export const TeamOverview = () => {
  const {teams, employees, updateTeamsOrder, setContextDate} = useApi()
  const [orderedTeams, setOrderedTeams] = useState<Team[]>(teams)
  const [date, setDateFunc] = React.useState<Date>(new Date())
  const setDate = (date: Date) => {
    setContextDate(date)
    setDateFunc(date)
  }
  const [onlyActive, setOnlyActive] = useState(false)
  useEffect(() => {
    setOrderedTeams(teams.sort((a,b) => a.order - b.order))
  }, [teams]);

  const employeeMap = useMemo(() => {
    const map = new Map<string, Employee>()
    employees.forEach(e => map.set(e.id, e))
    return map
  }, [employees])
  const teamsEmployees = useMemo(() => {
    const map = new Map<string, (Employee&{details: TeamMembership})[]>()
    teams.forEach(t => {
      const employees = t.members
        .filter(m => !m.isTemporary)
        .filter(m => moment(m.startedAt).isSame(date, 'day') || moment(m.startedAt).isBefore(date, 'day'))
        .filter(m => m.endedAt === null || moment(m.endedAt).isSame(date, 'day') || moment(m.endedAt).isAfter(date, 'day'))
        .map(m => ({...employeeMap.get(m.employeeId), details: m}))
        .filter(e => !!e)
        .filter(e => !onlyActive || e?.enabled) as (Employee&{details: TeamMembership})[]
      map.set(t.id, employees)
    })
    return map
  }, [teams, employeeMap, date, onlyActive])

  const emptyTeams = useMemo(() => {
    return teams.filter(t => {
      return ! t.members.some(m => !m.endedAt || m.endedAt.getTime() > new Date().getTime())
    }).map(t => t.id)
  }, [teams])
  const unassignedEmployees = useMemo(() => {
    const assignedEmployees = new Set([...teamsEmployees.values()].flat().map(e => e.id))
    return employees
      .filter(e => !assignedEmployees.has(e.id))
      .filter(e => !onlyActive || e.enabled)
  }, [teamsEmployees, employees, onlyActive])

  const updateTeamOrder = useCallback((team: Team, swapWithTeam: Team) => {
    setOrderedTeams(teams => {
      const teamA = teams.find(t => t.id === team.id)
      const teamB = teams.find(t => t.id === swapWithTeam.id)

      const newOrder = teams.map(t => {
        if (t.id === team.id) {
          return teamB ?? t
        }
        if (t.id === swapWithTeam.id) {
          return teamA ?? t
        }
        return t
      })
      updateTeamsOrder(newOrder.map(t => t.id))
      return newOrder
    })
  }, [updateTeamsOrder])

  if (teams.length === 0) {
    return <div
      className={"border border-slate-100 rounded py-8 flex items-center justify-center text-xs text-slate-700"}>
      <p>Er zijn geen teams</p>
    </div>
  }

  return <>
    <div className={"mb-6"}>
      <p className={'text-slate-800 mb-2 max-w-screen-sm'}>
        De samenstelling van een team kan verschillen per dag. Er kunnen bijvoorbeeld wijzigingen gepland staan in de toekomst. Toekomstige wijzigingen kun je zien en annuleren met behulp van de lijst aan de rechterkant.
        Je kunt met het aanpassen van het datumveld hieronder gemakkelijk zien welke teams er op een bepaalde dag zijn.
      </p>
      <div className={"flex items-center mt-4"}>
        <button className={"h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setDate(moment(date).clone().subtract(1, 'week').toDate())}><FontAwesomeIcon icon={faChevronLeft} /><FontAwesomeIcon icon={faChevronLeft} className={"-ml-1"} /></button>
        <button className={"h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setDate(moment(date).clone().subtract(1, 'day').toDate())}><FontAwesomeIcon icon={faChevronLeft} /></button>
        <div className={"-mt-1 mx-3"}><Input type={'date'} label={''} value={date} onChange={setDate}  /></div>
        <button className={"h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setDate(moment(date).clone().add(1, 'day').toDate())}><FontAwesomeIcon icon={faChevronRight} /></button>
        <button className={"h-8 w-8 rounded-full hover:bg-gray-200"} onClick={() => setDate(moment(date).clone().add(1, 'week').toDate())}><FontAwesomeIcon icon={faChevronRight} /><FontAwesomeIcon icon={faChevronRight} className={"-ml-1"} /></button>
        <div className={"ml-3"}>{!moment(date).isSame(new Date(), 'day') && <Button type={'secondary'} size={'md'} text={'Bekijk vandaag'} onClick={() => setDate(new Date())} />}</div>
        <label className={`h-10 px-4 flex cursor-pointer items-center space-x-3 ${onlyActive ? 'bg-blue-100' : ''} rounded-lg font-medium`}>
          <Toggle value={onlyActive} onChange={setOnlyActive} />
          <span>Alleen actief personeel</span>
        </label>
      </div>
    </div>
    <div>
      {orderedTeams.filter(t => t.id !== 'absence' && !t.isTemporary && (t.endedAt === null || moment(t.endedAt).isAfter(date))).map((t, i, ts) => {
        const previous = ts[i - 1] as Team | undefined
        const next = ts[i + 1] as Team | undefined
        return <div className={"flex items-stretch border border-slate-200 rounded-lg mb-3"} key={i}>
          <div className={"py-2 w-12 flex flex-col items-center justify-around"}>
            <IconButton type={'secondary'} disabled={previous === undefined} size={'xs'} icon={faChevronUp} onClick={() => previous && updateTeamOrder(t, previous)} />
            <IconButton type={'secondary'} disabled={next === undefined} size={'xs'} icon={faChevronDown} onClick={() => next && updateTeamOrder(t, next)} />
          </div>
          <div className={"flex-1 p-3"}>
            <TeamRowContent team={t} employees={teamsEmployees.get(t.id) || []} canEdit={true} canDelete={emptyTeams.includes(t.id)} date={date} />
          </div>
          <div className={"p-3 flex items-center"}>
            <TeamCategoryEdit team={t} />
          </div>
        </div>
      })}
      {unassignedEmployees.length > 0 && <div className={"flex items-stretch border border-slate-200 rounded-lg"}>
        <div className={"w-12"}></div>
        <div className={"flex-1 p-3"}>
          <div>
            <div className={"flex items-start gap-3 justify-start"}>
              <h2 className={"font-medium mb-2"}>Niet in een team</h2>
            </div>
            <div className={"flex flex-wrap gap-2"}>
              {unassignedEmployees.map((m, i) => {
                return <TeamRowContentEmployee employee={({...m, details: {} as TeamMembership})} key={i}/>
              })}
            </div>
          </div>
        </div>
      </div>}
    </div>
  </>
}

const TeamRowContent: FC<{
  team: Team,
  canEdit: boolean,
  canDelete: boolean,
  employees: (Employee & { details: TeamMembership })[]
  date: Date,
}> = (props) => {
  const editTeamModal = useModal({title: 'Teamnaam aanpassen', body: <EditTeamModal team={props.team}/>})
  const deleteTeamModal = useModal({title: 'Weet je het zeker?', body: <DeleteTeamModal team={props.team} date={props.date} />})

  return <div>
    <div className={"flex items-start gap-3 justify-start"}>
      <h2 className={"font-medium mb-2"}>{props.team.name}</h2>
      {props.canEdit && <button onClick={() => editTeamModal.open()} className={"text-slate-500 hover:text-slate-600"}>
        <FontAwesomeIcon icon={faPencil} className={"text-sm p-1"}/>
      </button>}
    </div>
    <div className={"flex flex-wrap gap-2"}>
      {props.employees.length > 0 ? props.employees.map((m, i) => {
        return <TeamRowContentEmployee employee={m} key={i}/>
      }) : <>
        {props.canDelete ? <>
          <Button type={"danger"} size={"xs"} text={"Team archiveren"} onClick={deleteTeamModal.open} icon={faArchive} />
        </> : <div className={"bg-slate-50 rounded-lg w-full text-xs h-6 flex items-center justify-center text-slate-600"}>Er
          is geen personeel toegewezen aan dit team
        </div> }
      </>}
    </div>

  </div>
}

const TeamRowContentEmployee: FC<{ employee: Employee&{details: TeamMembership} }> = (props) => {
  const switchTeamModal = useModal({size: 'md', title: 'Wissel team', body: <SwitchTeamModal employee={props.employee} teamMembership={props.employee.details} />})
  return <TinyEmployeeAvatar employee={props.employee} onClick={() => {
    switchTeamModal.open()
  }} hint={(props.employee.details.endedAt) ? {
    icon: faInfo,
    text: `Tot ${moment(props.employee.details.endedAt).format('DD-MM-YYYY')} in dit team` + (props.employee.details.reason ? ` (${props.employee.details.reason})` : '')
  } : undefined}/>
}

const TeamCategoryEdit: FC<{ team: Team }> = (props) => {
  const [category, setCategory]  = useState(props.team.category)
  const {editTeam} = useApi()
  const update = (newValue: string) => {
    setCategory(newValue)
    editTeam(props.team.id, props.team.name, newValue)
  }
  return <Select label={''} value={category} onChange={update} options={{
    'Werkplanning': 'Werkplanning',
    'Kantoorplanning': 'Kantoorplanning',
  }} />
}