import React, {Dispatch, FC, SetStateAction, useState} from "react";
import {useApi} from "../api/APIContext";
import {useModalControls} from "../components/layout/ModalProvider";
import {Task} from "../api/dto";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faSave,
  faTimes,
  faWarning
} from "@fortawesome/free-solid-svg-icons";
import {dateToString} from "../planning/util/datetime";
import {Button} from "../components/form/Button";
import {MultiSelectCalendar} from "../components/form/MultiSelectCalendar";


export const CopyTaskModal: FC<{task: Task, setDay: Dispatch<SetStateAction<Date>>}> = (props) => {
  const {batchPlanTask} = useApi()
  const { close } = useModalControls()
  const [selected, setSelected] = useState<Date[]>([])
  const [errors, setErrors] = React.useState<{ [date: string]: string }>({})
  const [isSaving, setIsSaving] = useState(false)
  const confirm = async () => {
    setIsSaving(true)
    const failed = await batchPlanTask(props.task.id, selected)
    console.log(failed)
    setErrors(failed)
    setIsSaving(false)
    if (Object.keys(failed).length === 0) {
      close()
    }
  }

  const remove = (d: Date) => {
    return () => {
      setSelected(x => x.filter(x => x.getTime() !== d.getTime()))
    }
  }

  if (Object.keys(errors).length > 0) {
    return <div className={"flex flex-col items-center justify-center space-y-2 py-4"}>
      <FontAwesomeIcon icon={faWarning} className={'text-xl text-red-900'} />
      <h2 className={"font-medium text-lg text-red-900 pb-4"}>Niet alle kopieën konden worden ingelpland</h2>
      <div className={"border border-slate-200 rounded w-full"}>
        <table className={"w-full"}>
          <thead>
            <tr>
              <th className={"p-2 border-b text-xs uppercase text-left font-medium text-slate-600"}>Datum</th>
              <th className={"p-2 border-b text-xs uppercase text-left font-medium text-red-600"}>Foutmelding</th>
              <th className={"p-2 border-b"}></th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(errors).map(([date, error]) => {
              return <tr key={date}>
                <td className={"py-1 px-2 text-sm font-medium text-slate-600"}>{date}</td>
                <td className={"py-1 px-2 text-sm font-medium text-red-600"}>Kon taak niet kwijt in de planning, is verplaatst naar overschot vak.</td>
                <td className={"py-1 px-2 text-sm font-medium"}>
                  <Button type={'secondary'} size={'sm'} text={'Naar week'} icon={faArrowRight} onClick={() => props.setDay(new Date(date))} />
                </td>
              </tr>
            })}
          </tbody>
        </table>
      </div>
      <Button type={'secondary'} size={'md'} text={'Oke'} onClick={() => close()}/>
    </div>
  }

  return <div className={"space-y-2"}>
    <div className={"flex space-x-4"}>
      <div>
        <MultiSelectCalendar taskDate={props.task.startAt!} setSelected={setSelected} selected={selected} />
      </div>
      <div className={"flex-1"}>
        {selected.length > 0 ? <div className={"max-h-[30vh] overflow-y-auto"}>
          <h2 className={"font-medium text-slate-900 mb-4"}>Kopiëren naar:</h2>
          {selected.sort((a,b) => a.getTime() - b.getTime()).map(function (d, i) {
            return <button key={i} className={"flex space-x-2 items-center group justify-between hover:bg-red-100 h-8 mb-1 px-2 w-full border border-slate-100 rounded"} onClick={remove(d)}>
              <span className={"text-slate-900 text-sm"}>{dateToString(d)} {d.getFullYear()}</span>
              <FontAwesomeIcon icon={faTimes} className={"text-sm text-slate-500 group-hover:text-red-500"} />
            </button>;
          })}
        </div> : <p className={"text-center text-slate-600 text-sm p-4"}>
          Selecteer data om de afspraak naar te kopiëren. <br />
          De afspraak zal dan ingepland worden op die dagen, op de zelfde tijd als de originele afspraak.
        </p>}

      </div>
    </div>
    <footer className={"-mx-4 px-4 mt-3 py-3 border-t border-slate-100 flex items-center space-x-4"}>
      <Button type={'primary'} disabled={isSaving} size={'md'} text={isSaving ? 'Opslaan...' : 'Aanmaken'} icon={faSave} onClick={confirm} />
      <Button type={'secondary'} size={'md'} text={'Terug'} onClick={() => close()}/>
    </footer>
  </div>
}


