import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {Task} from "../api/dto";
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";


export const DeleteTaskModal: FC<{task: Task}> = (props) => {
  const {deleteTask} = useApi()
  const confirm = async () => {
    await deleteTask(props.task)
  }

  return <>
    <p className={""}>
      <strong>Let op!</strong> Je staat op het punt om de taak <strong>{props.task.description}</strong> te verwijderen.
    </p>
    <ModalFooter icon={fa.faTimes} text={"Verwijderen"} onSubmit={confirm} />
  </>
}
