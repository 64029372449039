import {FC, useEffect, useMemo, useState} from "react";
import {usePlanner} from "./PlannerContext";
import moment from "moment";
import {useApi} from "../api/APIContext";
import {Order, Project} from "../api/dto";
import {Button, IconButton} from "../components/form/Button";
import {faPlus, faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import {AddOrderModal} from "../modals/AddOrderModal";
import {useModal} from "../components/layout/ModalProvider";
import {DeleteOrderModal} from "../modals/DeleteOrderModal";
import React from "react";
import {OrderAutocomplete} from "../components/form/Autocomplete";

export const OrderOverview: FC<{}> = () => {
  const {day} = usePlanner()
  const {orders, projects} = useApi()
  const monday = moment(day).set('day', 1)
  const days = [
    monday,
    monday.clone().add(1, 'day'),
    monday.clone().add(2, 'day'),
    monday.clone().add(3, 'day'),
    monday.clone().add(4, 'day'),
    monday.clone().add(5, 'day'),
    monday.clone().add(6, 'day'),
  ]
  const projectsMap = useMemo(() => {
    return projects.reduce((acc, p) => {
        acc[p.id] = p
        return acc
    }, {} as {[key: string]: Project})
  }, [projects])
  return <div className={"max-w-screen-2xl mx-auto"}>
    {days.map((d, i) => {
      const dayOrders = orders.filter(o => moment(o.date).isSame(d, 'day'))
      return <div key={i} className={"flex-1"}>
        <h1 className={"font-medium text-lg"}>{d.toDate().toLocaleDateString('nl', {weekday: 'long', day: 'numeric', month: 'long'})}</h1>
        <div className={'mt-2 mb-8'}>
          <OrdersCard orders={dayOrders} projectsMap={projectsMap} date={d.toDate()} />
        </div>
      </div>
    })}
  </div>
}

const OrdersCard: FC<{orders: Order[], projectsMap: {[key: string]: Project}, date: Date}> = (props) => {
  const {createOrder} = useApi()
  const addOrderModal = useModal({title: 'Bestelling toevoegen', body: <AddOrderModal date={props.date} />})
  const projects = new Set(props.orders.map(o => props.projectsMap[o.projectId]))
  const orders = props.orders.sort((a,b) => {
    return a.createdAt.getTime() > b.createdAt.getTime() ? 1 : -1
  })
  return <div className={"bg-white px-3 py-2 border border-slate-200 rounded"}>
    {orders.length === 0 ? <>
      <div className={"flex flex-col items-center my-4"}>
        <p className={"text-sm text-slate-800 mb-2"}>Nog geen bestellingen.</p>
      </div>
    </> : <>
      <table className={"w-full"}>
        <thead>
          <tr>
            <th className={"text-start pr-6 pb-2 text-sm font-medium text-blue-800 border-b border-slate-100"}>Projectnummer</th>
            <th className={"text-start pr-6 pb-2 text-sm font-medium text-blue-800 border-b border-slate-100"}>Projectnaam</th>
            <th className={"text-start pr-6 pb-2 text-sm font-medium text-blue-800 border-b border-slate-100"}>Artikel</th>
            <th className={"text-start pr-6 pb-2 text-sm font-medium text-blue-800 border-b border-slate-100"}>Hoeveelheid</th>
            <th className={"text-start pr-6 pb-2 text-sm font-medium text-blue-800 border-b border-slate-100"}>Verpakkingseenheid</th>
            <th className={"text-start pr-6 pb-2 text-sm font-medium text-blue-800 border-b border-slate-100"}>Opmerking</th>
          </tr>
        </thead>
        <tbody>
          {Array.from(projects).filter(p => !!p).map((project, i) => {
            const projectOrders = orders.filter(o => o.projectId === project.id)
            const addOrderToProject = async () => {
              await createOrder(Number(project.id), props.date, '_').catch(e => console.error(e))
            }
            return <React.Fragment key={i}>
              {projectOrders.map((order, j, orders) => {
                return <OrdersCardRow key={i * 1000 + j} order={order} orders={orders} project={project} />
              })}
              <tr>
                <td colSpan={2}></td>
                <td colSpan={4}>
                  <Button type={'secondary'} icon={faPlus} size={'sm'} text={'Nieuwe regel'} onClick={addOrderToProject} />
                </td>
              </tr>
            </React.Fragment>
          })}
        </tbody>
      </table>
    </>}
    <div>
      <Button type={'secondary'} icon={faPlus} size={'sm'} text={'Bestelling toevoegen'} onClick={() => addOrderModal.open()} />
    </div>
  </div>
}
const OrdersCardRow: FC<{order: Order, orders: Order[], project?: Project}> = (props) => {
  // Project info
  const firstOrderWithProject = props.orders.find(o => o.projectId === props.order.projectId)
  const shouldDisplayProject = firstOrderWithProject === props.order

  // State
  const [orderId, setOrderId] = useState(props.order.id)
  const [lastUpdate, setLastUpdate] = useState(props.order.updatedAt)
  const [isDone, setIsDone] = useState(props.order.isDone)
  const [text, setText] = useState(props.order.text === '_' ? '' : props.order.text)
  const [amount, setAmount] = useState(props.order.amount)
  const [quantity, setQuantity] = useState(props.order.quantity)
  const [quantityUnit, setQuantityUnit] = useState(props.order.quantityUnit === '' ? 'kg' : props.order.quantityUnit)
  const [remark, setRemark] = useState(props.order.remark)
  const [isDirty, setIsDirty] = useState(false)
  useEffect(() => {
    if (lastUpdate.getTime() < props.order.date.getTime() || orderId !== props.order.id) {
      setLastUpdate(props.order.updatedAt)
      setIsDone(props.order.isDone)
      setText(props.order.text === '_' ? '' : props.order.text)
      setAmount(props.order.amount)
      setQuantity(props.order.quantity)
      setQuantityUnit(props.order.quantityUnit)
      setRemark(props.order.remark)
    }
  }, [props.order, lastUpdate])
  const {updateOrder} = useApi()
  async function save() {
    await updateOrder(props.order.id, undefined, undefined, text.length > 0 ? text : undefined, amount, quantity, quantityUnit.length > 0 ? quantityUnit : undefined, remark.length > 0 ? remark : undefined, isDone)
      .catch(e => console.error(e))
    setIsDirty(false)
  }

  function dirty<T>(setter: (newValue: T) => void) {
    return (newValue: T) => {
      setter(newValue)
      setIsDirty(true)
    }
  }
  function toggleDone() {
    const newValue = !isDone
    setIsDone(newValue)
    updateOrder(props.order.id, undefined, undefined, undefined, undefined, undefined, undefined, undefined, newValue)
      .catch(e => console.error(e))
  }
  const deleteOrderModal = useModal({title: 'Verwijderen', body: <DeleteOrderModal order={props.order} />})
  const allOrderItems = [...new Set(props.orders.map(order => order.text))];


  return <tr className={`text-sm ${isDone ? 'text-slate-500' : 'text-slate-700'}`}>
    <td className={"font-mono xl:w-32 text-sm pr-4"}>{shouldDisplayProject ? <>{props.project?.projectNumber ? `#${props.project?.projectNumber}` : ''}</> : <></>}</td>
    <td className={"font-medium text-sm pr-4"}>{shouldDisplayProject ? <>{props.project?.description ?? ''}</> : <></>}</td>
    <td className={`flex items-center pr-4`}>
      <label className={`cursor-pointer py-1 px-2 hover:bg-blue-200 rounded my-1 mr-2 ${isDone ? 'bg-blue-100' : 'bg-blue-50'} block`}>
        <input type={'checkbox'} checked={isDone} onChange={toggleDone} className={""} />
      </label>
      {/*<TableTextInput value={text} setValue={dirty(setText)} save={save} />*/}
      <OrderAutocomplete value={text} options={allOrderItems} onChange={dirty(setText)} onBlur={save}/>
    </td>
    <td className={`xl:w-16 pr-4`}>
      <TableNumberInput value={amount} setValue={dirty(setAmount)} save={save}/>
    </td>
    <td className={`flex space-x-1 pr-4`}>
      <div className={'xl:w-32 my-1'}>
        <TableNumberInput value={quantity} setValue={dirty(setQuantity)} save={save} />
      </div>
      <div className={'xl:w-16 my-1'}>
        <TableTextInput value={quantityUnit} setValue={dirty(setQuantityUnit)} save={save} />
      </div>
    </td>
    <td className={``}>
      <TableTextInput value={remark} setValue={dirty(setRemark)} save={save} />
    </td>
    <td className={`pl-4`}>
      <div className={"my-1 flex"}>
        {/*<IconButton icon={faSave} disabled={!isDirty} type={'secondary'} size={'sm'} onClick={save} />*/}
        <IconButton icon={faTimes} type={'danger'} size={'sm'} onClick={deleteOrderModal.open} />
      </div>
    </td>
  </tr>
}
const TableTextInput: FC<{value: string, setValue: (newValue: string) => void, className?: string, save: () => void}> = (props) => {
  return <input type={'text'} value={props.value} onChange={e => props.setValue(e.target.value)} className={`w-full px-2 py-1 border border-slate-200 rounded ${props.className??''}`} onBlur={props.save} />
}
const TableNumberInput: FC<{value: number, setValue: (newValue: number) => void, className?: string, save: () => void}> = (props) => {
  return <input type={'number'} value={props.value} onChange={e => props.setValue(Number(e.target.value))} className={`w-full px-2 py-1 border border-slate-200 rounded ${props.className??''}`} onBlur={props.save} />
}