export function dateToString(date: Date) {
    return date.toLocaleDateString('nl-NL', {weekday: 'short', month: 'short', day: 'numeric'})
}

export function getDefaultDay(): Date {
    const d = new Date()
    d.setHours(0)
    d.setMinutes(0)
    d.setSeconds(0)
    return d
}
