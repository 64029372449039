import {useCallback} from "react";
import {useApi} from "../../api/APIContext";

interface ColorTheme {bg: string, bgHover: string, text: string, lightText: string, border: string}
type NamedColorTheme = ColorTheme & {name: string}
const legacyThemes: {[key: string]: NamedColorTheme} = {
  blue: {
    name: 'Blauw',
    bg: "bg-blue-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-blue-300",
    border: "border-blue-600",
  },
  'blue-inverted': {
    name: 'Blauw',
    bg: "bg-blue-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-blue-800",
    border: "border-blue-800",
  },
  red: {
    name: 'Rood',
    bg: "bg-red-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-red-300",
    border: 'border-red-600',
  },
  'red-inverted': {
    name: 'Rood',
    bg: "bg-red-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-red-800",
    border: "border-red-800",
  },
  green: {
    name: 'Groen',
    bg: "bg-green-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-green-300",
    border: 'border-green-600',
  },
  'green-inverted': {
    name: 'Groen',
    bg: "bg-green-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-green-800",
    border: "border-green-800",
  },
  yellow: {
    name: 'Geel',
    bg: "bg-yellow-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-yellow-300",
    border: 'border-yellow-600',
  },
  'yellow-inverted': {
    name: 'Geel',
    bg: "bg-yellow-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-yellow-800",
    border: "border-yellow-800",
  },
  purple: {
    name: 'Paars',
    bg: "bg-purple-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-purple-300",
    border: 'border-purple-600',
  },
  'purple-inverted': {
    name: 'Paars',
    bg: "bg-purple-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-purple-800",
    border: "border-purple-800",
  },
  pink: {
    name: 'Roze',
    bg: "bg-rose-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-rose-300",
    border: 'border-rose-600',
  },
  'pink-inverted': {
    name: 'Roze',
    bg: "bg-pink-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-pink-800",
    border: "border-pink-800",
  },
  orange: {
    name: 'Oranje',
    bg: "bg-orange-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-orange-300",
    border: 'border-orange-600',
  },
  'orange-inverted': {
    name: 'Oranje',
    bg: "bg-orange-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-orange-800",
    border: "border-orange-800",
  },
  gray: {
    name: 'Grijs',
    bg: "bg-gray-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-gray-300",
    border: 'border-gray-600',
  },
  'gray-inverted': {
    name: 'Grijs',
    bg: "bg-gray-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-gray-800",
    border: "border-gray-800",
  },
}

export const useThemes = () => {
  const {colors} = useApi()
  const getThemes = useCallback(function(includeDisabled = false) {
    return colors.filter(c => includeDisabled || c.enabled).map(c => c.id)
  }, [colors])
  const getTheme = useCallback(function (color?: "blue"|"red"|"green"|"yellow"|"purple"|"pink"|"orange"|string, inverted = false): NamedColorTheme&{color:string} {
    const fallback: NamedColorTheme&{color:string} = inverted ? {...legacyThemes['blue-inverted'], color: 'blue-inverted'} : {...legacyThemes.blue, color: 'blue'}
    if (color === undefined) {
      return fallback
    }
    const colorTheme = colors.find(c => c.id === color)
    if (!colorTheme) {
      const legacyColorTheme: NamedColorTheme|undefined = legacyThemes[`${color}${inverted ? '-inverted' : ''}`] ?? legacyThemes[color]
      if (legacyColorTheme === undefined) {
        return fallback
      }
      return {...legacyColorTheme, color: color}
    }
    const theme: ColorTheme|undefined = themes[`${colorTheme.color}${inverted ? '-inverted' : ''}`] ?? themes[colorTheme.color]
    if (theme === undefined) {
      return fallback
    }
    return {
      ...theme,
      name: colorTheme.name,
      color: colorTheme.id,
    }
  }, [colors])
  return {getTheme, getThemes}
}

const themes: {[color: string]: ColorTheme} = {
  'slate': {
    bg: "bg-slate-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-slate-300",
    border: "border-slate-600",
  },
  'slate-inverted': {
    bg: "bg-slate-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-slate-800",
    border: "border-slate-800",
  },
  'gray': {
    bg: "bg-gray-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-gray-300",
    border: "border-gray-600",
  },
  'gray-inverted': {
    bg: "bg-gray-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-gray-800",
    border: "border-gray-800",
  },
  'zinc': {
    bg: "bg-zinc-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-zinc-300",
    border: "border-zinc-600",
  },
  'zinc-inverted': {
    bg: "bg-zinc-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-zinc-800",
    border: "border-zinc-800",
  },
  'neutral': {
    bg: "bg-neutral-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-neutral-300",
    border: "border-neutral-600",
  },
  'neutral-inverted': {
    bg: "bg-neutral-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-neutral-800",
    border: "border-neutral-800",
  },
  'stone': {
    bg: "bg-stone-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-stone-300",
    border: "border-stone-600",
  },
  'stone-inverted': {
    bg: "bg-stone-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-stone-800",
    border: "border-stone-800",
  },
  'red': {
    bg: "bg-red-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-red-300",
    border: "border-red-600",
  },
  'red-inverted': {
    bg: "bg-red-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-red-800",
    border: "border-red-800",
  },
  'orange': {
    bg: "bg-orange-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-orange-300",
    border: "border-orange-600",
  },
  'orange-inverted': {
    bg: "bg-orange-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-orange-800",
    border: "border-orange-800",
  },
  'amber': {
    bg: "bg-amber-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-amber-300",
    border: "border-amber-600",
  },
  'amber-inverted': {
    bg: "bg-amber-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-amber-800",
    border: "border-amber-800",
  },
  'yellow': {
    bg: "bg-yellow-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-yellow-300",
    border: "border-yellow-600",
  },
  'yellow-inverted': {
    bg: "bg-yellow-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-yellow-800",
    border: "border-yellow-800",
  },
  'lime': {
    bg: "bg-lime-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-lime-300",
    border: "border-lime-600",
  },
  'lime-inverted': {
    bg: "bg-lime-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-lime-800",
    border: "border-lime-800",
  },
  'green': {
    bg: "bg-green-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-green-300",
    border: "border-green-600",
  },
  'green-inverted': {
    bg: "bg-green-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-green-800",
    border: "border-green-800",
  },
  'emerald': {
    bg: "bg-emerald-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-emerald-300",
    border: "border-emerald-600",
  },
  'emerald-inverted': {
    bg: "bg-emerald-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-emerald-800",
    border: "border-emerald-800",
  },
  'teal': {
    bg: "bg-teal-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-teal-300",
    border: "border-teal-600",
  },
  'teal-inverted': {
    bg: "bg-teal-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-teal-800",
    border: "border-teal-800",
  },
  'cyan': {
    bg: "bg-cyan-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-cyan-300",
    border: "border-cyan-600",
  },
  'cyan-inverted': {
    bg: "bg-cyan-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-cyan-800",
    border: "border-cyan-800",
  },
  'sky': {
    bg: "bg-sky-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-sky-300",
    border: "border-sky-600",
  },
  'sky-inverted': {
    bg: "bg-sky-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-sky-800",
    border: "border-sky-800",
  },
  'blue': {
    bg: "bg-blue-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-blue-300",
    border: "border-blue-600",
  },
  'blue-inverted': {
    bg: "bg-blue-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-blue-800",
    border: "border-blue-800",
  },
  'indigo': {
    bg: "bg-indigo-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-indigo-300",
    border: "border-indigo-600",
  },
  'indigo-inverted': {
    bg: "bg-indigo-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-indigo-800",
    border: "border-indigo-800",
  },
  'violet': {
    bg: "bg-violet-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-violet-300",
    border: "border-violet-600",
  },
  'violet-inverted': {
    bg: "bg-violet-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-violet-800",
    border: "border-violet-800",
  },
  'purple': {
    bg: "bg-purple-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-purple-300",
    border: "border-purple-600",
  },
  'purple-inverted': {
    bg: "bg-purple-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-purple-800",
    border: "border-purple-800",
  },
  'fuchsia': {
    bg: "bg-fuchsia-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-fuchsia-300",
    border: "border-fuchsia-600",
  },
  'fuchsia-inverted': {
    bg: "bg-fuchsia-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-fuchsia-800",
    border: "border-fuchsia-800",
  },
  'pink': {
    bg: "bg-pink-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-pink-300",
    border: "border-pink-600",
  },
  'pink-inverted': {
    bg: "bg-pink-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-pink-800",
    border: "border-pink-800",
  },
  'rose': {
    bg: "bg-rose-700",
    text: 'text-white',
    bgHover: "hover:bg-[#FFF1]",
    lightText: "text-rose-300",
    border: "border-rose-600",
  },
  'rose-inverted': {
    bg: "bg-rose-200",
    text: 'text-black',
    bgHover: "hover:bg-[#0001]",
    lightText: "text-rose-800",
    border: "border-rose-800",
  },
}