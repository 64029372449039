import React, {FC} from "react";
import {Card} from "../components/Card";
import {Button, IconButton} from "../components/form/Button";
import {faEdit, faEllipsisVertical, faPencil, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useModal} from "../components/layout/ModalProvider";
import {AddGlobalHolidayModal} from "../modals/AddGlobalHolidayModal";
import {useApi} from "../api/APIContext";
import {dateToString} from "../planning/util/datetime";
import {Holiday, Supplier} from "../api/dto";
import {DeleteGlobalHolidayModal} from "../modals/DeleteGlobalHolidayModal";
import {AddSupplierModal} from "../modals/AddSupplierModal";
import {EditSupplierModal} from "../modals/EditSupplierModal";

export const Leveranciers: FC = () => {
  const addSupplierModal = useModal({'title': 'Leverancier Toevoegen', body: <AddSupplierModal />})
  const {suppliers} = useApi()
  return (
    <main className={"flex-1 overflow-y-scroll max-h-[calc(100vh-4rem)]"}>
      <div className={"mx-6 my-8"}>
        <div className={"max-w-screen-md mx-auto"}>
          <div className={"flex justify-between items-center h-12 mb-4"}>
            <h1 className={"flex items-center text-2xl font-bold"}>Leveranciers</h1>
            <Button icon={faPlus} text={'Leverancier toevoegen'} type={'primary'} size={'md'} onClick={() => addSupplierModal.open() }/>
          </div>
          <Card title={'Leveranciers'}>
            <div className={"border border-slate-200 rounded -mx-1"}>
              {suppliers.length === 0 && <div className={"flex flex-col justify-center items-center px-3 py-8"}>
                <div className={"mb-4"}>Er zijn nog geen leveranciers.</div>
                <Button icon={faPlus} text={'Voeg leverancier toe'} type={'secondary'} size={'md'} onClick={() => addSupplierModal.open() }/>
              </div>}
              {suppliers.map((s, i) => <div key={i} className={`${i > 0 ? 'border-t border-slate-200' : ''} flex items-center px-3 py-2`} >
                <div className={"flex-1"}>
                  <div className="font-medium text-slate-900">{s.name}</div>
                </div>
                <SupplierControls supplier={s} />
              </div>)}
            </div>
          </Card>
        </div>
      </div>
    </main>
  );
}

const SupplierControls: FC<{supplier: Supplier}> = ({supplier}) => {
  const editSupplierModal = useModal({title: "Naam wijzigen", body: <EditSupplierModal supplier={supplier} />})
  return <IconButton icon={faPencil} type={"secondary"} size={'md'} onClick={() => editSupplierModal.open()} />
}









