import {QuantityType, Task, TASK_QUANTITY_TYPE_OTHER} from "../../api/dto";
import {useCallback} from "react";
import {useApi} from "../../api/APIContext";

export const useTaskTypes = () => {
  const {quantityTypes} = useApi()

  const getQuantityType = useCallback((typeString: string|null|undefined) => {
    const type = typeString ?? TASK_QUANTITY_TYPE_OTHER
    return quantityTypes.find(qt => qt.id === type || qt.name === type)
  }, [quantityTypes])

  const isQuantityTypeOther = (quantityType: QuantityType): boolean => {
    return quantityType.id === TASK_QUANTITY_TYPE_OTHER || quantityType.name === TASK_QUANTITY_TYPE_OTHER
  }

  const getTaskName = useCallback((task: Task): string => {
    const quantityType = getQuantityType(task.quantityType)
    if (quantityType === undefined || (isQuantityTypeOther(quantityType))) {
      if (task.quantityAmount !== null && task.quantityAmount > 0) {
        if (quantityType != null && !quantityType.hideArea) {
          return `${task.quantityAmount ?? 0}m²` + (task.description.trim() === '' ? '' : ` · ${task.description}`)
        } else {
          return `${task.description}`
        }
      }
      return task.description
    }
    return (!quantityType.hideArea ? `${task.quantityAmount ?? 0}m² ${quantityType.name}` : `${quantityType.name}`) + (task.description.trim() === '' ? '' : ` · ${task.description}`)
  }, [getQuantityType])

  const getTaskColor = useCallback((task: Task): string|null => {
    if (task.color) {
      return task.color
    }
    const quantityType = getQuantityType(task.quantityType)
    if (quantityType === undefined) {
      return null
    }
    return quantityType.defaultColor
  }, [getQuantityType])

  const getTaskTemplate = useCallback((taskType: string): string[]|null => {
    const quantityType = getQuantityType(taskType)
    if (quantityType === undefined) {
      return null
    }
    return quantityType.descriptionTemplate
  }, [getQuantityType])

  return {
    getTaskName,
    getTaskColor,
    getTaskTemplate: getTaskTemplate,
  }
}